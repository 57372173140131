import { Component, OnInit, ElementRef, Output, EventEmitter, Input, ViewChild, Inject } from '@angular/core';
import {Table} from 'primeng/table';
import {emtrConstant, DefaultValues} from '../../constants/emtrConstants';
import {RuleService} from '../../services/rule-service/rule.service';
import {FundFamilyTableHead, searchFundFamilyTableHead} from '../../models/search-fund-Family-table-header.model';
import {AutoCompleteSecuritiesTableHead, searchSecuritiesTableHeads } from '../../models/search-securities-table-header.model';
import {SharedService, SharedConstant} from '@ems/shared';

@Component({
  selector: 'tr-securities-to-restrict',
  templateUrl: './securities-to-restrict.component.html',
  styleUrls: ['./securities-to-restrict.component.scss']
})
export class SecuritiesToRestrictComponent implements OnInit {
  text: string;
  results: any;
  securities: any;
  @Input() autoCompleteSelectedData: any[] = [];
  @Input() multiselectSelectedData: any[] = [];
  @Input() rulesCategory: any;
  @Input() ruleType: any;
  @Input() selectedRulesCategory: any;
  @Input() isInstGrp = false;
  @Input() countOfInstGrp: any;

  gridData: any = [];
  cols: any[];
  selectedData: any[] = [];
  noOfRecords: any = DefaultValues.defaultTableRecords;
  totalRecords: any;
  showErr = false;
  errorMsg: any;
  getConstant = emtrConstant;
  getSharedConstant = SharedConstant;
  @Output() securitiesChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() throwErrorIndSec: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitSelectedvalues: EventEmitter<string> = new EventEmitter<string>();
  fundFamilyTableHeadCols: FundFamilyTableHead[] = searchFundFamilyTableHead;
  autoCompleteSecuritiesTableHeadCols: AutoCompleteSecuritiesTableHead[] = searchSecuritiesTableHeads;
  loadSecurityModal = false;
  searchSecurities: any;
  getSecurities = true;

  @ViewChild(Table, { static: true }) tableComponent: Table;

  constructor(private el: ElementRef, public ruleService: RuleService, @Inject('commonConstants') public commonConstants, public sharedService: SharedService) { }

  ngOnInit() {
    this.cols = (this.ruleType === this.getConstant.rulesConstant.autoComplete.FNDISUTxt) ? this.fundFamilyTableHeadCols : this.autoCompleteSecuritiesTableHeadCols;
    this.tableComponent.reset();
    this.gridData = (this.autoCompleteSelectedData) ? this.gridData.concat(this.autoCompleteSelectedData) : [];
    this.selectedData = (this.autoCompleteSelectedData) ? this.selectedData.concat(this.autoCompleteSelectedData) : [];
    this.totalRecords = this.gridData.length;
  }

  autocompleteSearch(event) {
    let query = event.query;
    query = encodeURIComponent(`${query}`);
    this.sharedService.clearErrorMessage();
    this.throwErrorIndSec.emit({msg: '', component: 'secToRes'});
    if (this.getSecurities && this.ruleType !== this.getConstant.rulesConstant.autoComplete.FNDISUTxt) {
      this.ruleService.getAutoSecurities(query, 'AUTOCOMPLETE').subscribe(securities => {
        this.commonResponseHandler(securities);
          }
      );
    } else if (this.ruleType === this.getConstant.rulesConstant.autoComplete.FNDISUTxt) {
      this.ruleService.getAutoSecurities(query, 'FUNDFAMILY').subscribe(securities => {
          this.commonResponseHandler(securities);
        }
      );
    }
    this.getSecurities = true;
  }

  commonResponseHandler(securities) {
    this.results = securities.data;
    /*Condition to show error message if API gives no response*/
    if (this.results && this.results.length > 0) {
      this.showErr = false;
      this.errorMsg = '';
      this.throwErrorIndSec.emit({msg: '', component: 'secToRes'});
    } else {
      this.showErr = true;
      this.throwErrorIndSec.emit({msg: this.getConstant.rulesConstant.autoComplete.noRecords, component: 'secToRes'});
    }
    return this.results;
  }
  highlightTxt(displaytxt, searchtxt) {
    if (displaytxt !== null) {
      searchtxt = this.sharedService.searchTextInAutoComplete(searchtxt);
      return displaytxt.replace(new RegExp(searchtxt, 'gi'), match => {
        return '<strong>' + match + '</strong>';
      });
    }
  }
  selectedVal(event) {
    /*Clear the search text*/
    this.text = '';

    /*condition to check if same entry already present in data Grid*/
    if (this.gridData) {
      if (this.ruleType !== this.getConstant.rulesConstant.autoComplete.FNDISUTxt && this.gridData.filter(item => (item.tickerSymbol === event.securityRow.tickerSymbol && item.cusip === event.securityRow.cusip)).length === 0) {
        this.setGridData(event);
      } else if (this.ruleType === this.getConstant.rulesConstant.autoComplete.FNDISUTxt && this.gridData.filter(item => (item.id === event.id && item.name === event.name)).length === 0) {
        this.setGridData(event);
      } else {
        /*show Error Message*/
        this.showErr = true;
        this.errorMsg = this.getConstant.rulesConstant.autoComplete.duplicateRecord;
        this.throwErrorIndSec.emit({msg: '', component: 'secToRes'});
      }
    }
    this.securitiesChange.emit(this.selectedData);
  }

  setGridData(event) {
      this.showErr = false;
      this.errorMsg = '';
      this.throwErrorIndSec.emit({msg: '', component: 'secToRes'});
      if (this.ruleType === this.getConstant.rulesConstant.autoComplete.FNDISUTxt) {
        this.gridData.push(event);
        this.selectedData.push(event);
      } else {
        this.gridData.push(event.securityRow);
        this.selectedData.push(event.securityRow);
      }
      /*if (this.selectedData.length <= 1) {
        // Function to trigger click event of table to highlight selectAll checkbox,
        // Since table loaded at first and we are pushing data later.
        this.el.nativeElement.querySelector('.p-paginator-bottom button').click();
      }*/
      this.totalRecords = this.gridData.length;
      this.gridData = [...this.gridData];
      this.selectedData = [...this.selectedData];
  }

  updateSecurities() {
    this.securitiesChange.emit(this.selectedData);
  }

  receiveDataFromChild(e) {
    this.noOfRecords = parseInt(e, 10);
    this.gridData = [...this.gridData];
  }

  updateMultiselectValues(selectedValues) {
    this.searchSecurities = selectedValues;
    this.emitSelectedvalues.emit(selectedValues);
  }

  getSelectedRecords(element) {
    this.results = element;
    if (this.ruleType === this.getConstant.rulesConstant.autoComplete.FNDISUTxt) {
      const selectedArray = this.gridData.map( e => e.id);
      const returnedValue = element.filter(val => (selectedArray.indexOf(val.id) === -1));
      const duplicateValues = element.filter(val => (selectedArray.indexOf(val.id) !== -1));
      this.verifySelectedRecords(returnedValue, duplicateValues);
    } else {
      const selectedArray = this.gridData.map( e => e.ssid);
      const returnedValue = element.filter(val => (selectedArray.indexOf(val.ssid) === -1));
      const duplicateValues = element.filter(val => (selectedArray.indexOf(val.ssid) !== -1));
      this.verifySelectedRecords(returnedValue, duplicateValues);
    }
  }

  verifySelectedRecords(returnedValue, duplicateValues) {
    this.gridData = this.gridData.concat(returnedValue);
    this.selectedData = this.selectedData.concat(returnedValue);
    this.totalRecords = this.gridData.length;
    this.securitiesChange.emit(this.selectedData);
    if (duplicateValues.length) {
      this.showErr = true;
      this.errorMsg = this.getConstant.rulesConstant.autoComplete.duplicateRecord;
      this.throwErrorIndSec.emit({msg: this.errorMsg, component: 'secToRes'});
    }
  }

  openModal(event: any, modal) {
    if (this.text === '') {
      this.showErr = false;
      this.throwErrorIndSec.emit({msg: '', component: 'secToRes'});
    }
    if (event.key === 'Enter' && this.text && this.text.trim()) {
      event.target.blur();
      this.getSecurities = false;
      modal.openModal(event, this.searchSecurities, this.text);
    }
  }
}
