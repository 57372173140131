<p-confirmDialog #cd key="confirmBox" autofocus="false" defaultButton="reject" [closable]="closeIconButton" icon="sdps-messaging__icon sch sch-exclamation-octagon-2x" class='emtr-warning-box' [ngClass]="businessJustification && deactivateClientForm ? 'deactivation_modal' : ''">
  <p-footer>
    <div class ="sdps-business-justification" *ngIf="businessJustification && deactivateClientForm">
      <div class="sdps-text-left">
        <span class="sdps-font-weight-bold" [innerHTML]="sharedConstant.confirmationDialog.businessJustificationTitle"></span>
        <span class="sdps-text-left sdps-text-market-red" [innerHTML]="sharedConstant.confirmationDialog.asterix"></span>
      </div>
    <form [formGroup]="deactivateClientForm">
      <textarea class="p-inputtextarea p-inputtext sdps-p-vertical_x-small group-desc sdps-reason-text" rows="3" cols="50" maxlength="300"
        formControlName="businessJustificationValue" emsCharacterLimit [charMaxLimit]="sharedConstant.charLimitValues.threeHundredMaxLimit"
        (emsCharacterLimitCheck)="showCharLimit = $event"></textarea>
    </form>
    </div>
 <div *ngIf="deactivateClientForm.get('businessJustificationValue').value.length >= showCharLimit" class="col-12">
   <p class="sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small"
      *ngIf="deactivateClientForm.get('businessJustificationValue').value.length >= showCharLimit">
     {{sharedConstant.characterLimit}}
     <span class="character-limit">{{deactivateClientForm.get('businessJustificationValue').value.length}}/{{sharedConstant.charLimitValues.threeHundredMaxLimit}}</span>
   </p>
 </div>
      <button *ngIf= "shouldHideAcceptButton" type="button" id="warning-accept" [disabled]="businessJustification && deactivateClientForm.get('businessJustificationValue').value.trim().length < 1" class="cursor button button-save sdps-p-vertical_x-small sdps-p-horizontal_x-large" pButton [label]=acceptButton (click)="cd.accept()"></button>
      <button type="button" id="warning-reject" class="buttons button-default-cancel sdps-p-vertical_x-small sdps-p-horizontal_x-large" pButton [label]=rejectButton #inputEl (click)="cd.reject()"></button>
  </p-footer>
</p-confirmDialog>
