import {ModuleWithProviders, NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {SharedComponent} from './shared.component';
import {PrimeNgModule} from './common_modules/prime-ng/prime-ng.module';
import {BulkUploadComponent} from './component/bulk-upload/bulk-upload.component';
import {BasicDatatableHeaderIconComponent} from './component/basic-datatable-header-icon/basic-datatable-header-icon.component';
import {TableRecordCountComponent} from './component/table-record-count/table-record-count.component';
import {StepWizardComponent} from './component/step-wizard/step-wizard.component';
import {WorkflowService} from './services/workflow/workflow.service';
import {StepWizardButtonsComponent} from './component/step-wizard-buttons/step-wizard-buttons.component';
import {AccordionComponent} from './component/accordion/accordion.component';
import {ToastMessageComponent} from './component/toast-message/toast-message.component';
import {DragDropDirective} from './directives/drag-drop-directive/drag-drop.directive';
import {ConfirmMessageComponent} from './component/confirm-message/confirm-message.component';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ServiceErrorComponent} from './component/service-error/service-error.component';
import {SharedManageEmployeeGroupComponent} from './component/shared-manage-employee-group/shared-manage-employee-group.component';
import {DatePipe} from '@angular/common';
import {TimePatterncheckDirective} from './directives/time-pattern-directive/time-patterncheck.directive';
import {SharedManageEmployeeAccountsComponent} from './component/shared-manage-employee-accounts/shared-manage-employee-accounts.component';
import {TextboxPatterncheckDirective} from './directives/pattern-directive/textbox-patterncheck.directive';
import {FormDataService} from './services/form-data/form-data.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {SharedManageEmployeesComponent} from './component/shared-manage-employees/shared-manage-employees.component';
import { ManageEmployeeDataService } from './services/manage-employee/manage-employee-data.service';
import {EmailPatterncheckDirective} from './directives/pattern-directive/email-patterncheck.directive';
import { ViewHistoryComponent } from './component/view-history/view-history.component';
import {NamePatternCheckDirective} from './directives/pattern-directive/name-pattern-check.directive';
import {NumberPatterncheckDirective} from './directives/pattern-directive/number-patterncheck.directive';
import {AlphaNumericPatterncheckDirective} from './directives/pattern-directive/alpha-numeric-patterncheck.directive';
import { DonutChartComponent } from './component/donut-chart/donut-chart.component';
import {ChartModule} from 'primeng/chart';
import {CustomSortService} from './services/custom-sort/custom-sort.service';
import {ConfigUrlService} from './services/config-url/config-url.service';
import {CharacterLimitDirective} from './directives/char-limit-directive/character-limit.directive';
import { ClientNamePatterncheckDirective } from './directives/pattern-directive/client-name-patterncheck.directive';
import {AccountNumberValidationService} from './services/accountNumberValidation/account-number-validation.service';
import { EmployeeIdPatterncheckDirective } from './directives/pattern-directive/employee-id-patterncheck.directive';
import { DateRangeFilterComponent } from './component/date-range-filter/date-range-filter.component';
import {CommonValidationService} from './services/commonValidation/common-validation.service';
import {UploadsFileNamePatterncheckDirective} from './directives/pattern-directive/uploads-file-name-patterncheck.directive';
import {EmsMultiselectComponent} from './component/ems-multiselect/ems-multiselect.component';
import {K4masterAccountPatterncheckDirective} from './directives/pattern-directive/k4master-account-patterncheck.directive';
import {ManageEmployeesSubHeaderComponent} from './component/manage-employees-sub-header/manage-employees-sub-header.component';
import { SharedMasterGroupsComponent } from './component/shared-master-groups/shared-master-groups.component';
import { ChangeRequestIdPatterncheckDirective } from './directives/pattern-directive/change-request-id-patterncheck.directive';
import { NgChartjsModule } from 'ng-chartjs';

const sharedComponent = [
  SharedComponent,
  BulkUploadComponent,
  BasicDatatableHeaderIconComponent,
  TableRecordCountComponent,
  ToastMessageComponent,
  DragDropDirective,
  ConfirmMessageComponent,
  SharedManageEmployeeGroupComponent,
  ServiceErrorComponent,
  StepWizardComponent,
  StepWizardButtonsComponent,
  AccordionComponent,
  TimePatterncheckDirective,
  SharedManageEmployeeAccountsComponent,
  TextboxPatterncheckDirective,
  SharedManageEmployeesComponent,
  EmailPatterncheckDirective,
  ViewHistoryComponent,
  NamePatternCheckDirective,
  NumberPatterncheckDirective,
  AlphaNumericPatterncheckDirective,
  DonutChartComponent,
  CharacterLimitDirective,
  ClientNamePatterncheckDirective,
  EmployeeIdPatterncheckDirective,
  DateRangeFilterComponent,
  UploadsFileNamePatterncheckDirective,
  EmsMultiselectComponent,
  K4masterAccountPatterncheckDirective,
  ManageEmployeesSubHeaderComponent,
  SharedMasterGroupsComponent,
  ChangeRequestIdPatterncheckDirective
];

@NgModule({
  declarations: [
    sharedComponent
  ],
  imports: [
    PrimeNgModule,
    FormsModule,
    ReactiveFormsModule,
    ChartModule,
    NgChartjsModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [
    sharedComponent
  ],
  providers: [ConfirmationService, MessageService, DatePipe, WorkflowService, FormDataService, ManageEmployeeDataService, CustomSortService, ConfigUrlService, AccountNumberValidationService, CommonValidationService]
})
export class SharedModule {
  public static forRoot(commonConstants: any, entitlementConst: any): ModuleWithProviders<SharedModule> {

    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: 'commonConstants', useValue: commonConstants
        },
        {
          provide: 'entitlementConst', useValue: entitlementConst
        }
      ]
    };
  }
}
