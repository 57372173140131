import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Inject,
  Output,
  OnDestroy,
  ElementRef, AfterViewChecked,
} from '@angular/core';
import {ManageUsersService} from '@core_services/manage-users/manage-user.service';
import {userTableHeader, UserTableHead} from './manage-user-table-header';
import {DateRangeFilterComponent} from '@ems/shared';
import {MessageService} from 'primeng/api';
import {ShellConstant} from '@shell_components/constants/shellConstants';
import {EmtrPaginationValues, EmtrPagination} from '@ems/emtr';
import { EventEmitter } from 'events';
import {Paginator} from 'primeng/paginator';
import {ActivatedRoute, Router} from '@angular/router';
import {AddlinkService, RequestListService} from '@ems/addlink';
import {HeaderService} from '@core_services/header/header.service';
import {LocalStorageService} from '@core_services/local-storage.service';
import { Subscription } from 'rxjs';
import { Table } from 'primeng/table';
import { entitlementConst } from '@shell_components/constants/entitlementConstants';
import {AddlinkConstant} from '../../../../projects/ems/addlink/src/lib/constants/addlinkConstants';
import {ToastMessageComponent, CustomSortService, SharedService ,SharedConstant} from '@ems/shared';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import * as temp from 'moment';
const moment = temp['default'];

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent implements OnInit, OnDestroy, AfterViewChecked {

  private currentOrgSubscription: Subscription;

  totalUsers: any = 0;
  paginationCount: any = 0;
  getUsersConstant = ShellConstant;
  sharedConstant = SharedConstant;
  getAdlConst = AddlinkConstant;
  entitlementConst = entitlementConst;
  public requestColumns: any[];

  public requests: Request[];

  public rowsDisplay: number;

  userListTableCols: UserTableHead [] = userTableHeader;
  totalRecords: any = 0;
  selection = false;
  landingPageMsg: any;
  clientName: string;
  businessUnit: string;
  pageObject: EmtrPagination = EmtrPaginationValues;

  @Output() paginateUsersList: any = new EventEmitter();
  @ViewChild('confirmationMessage', { static: true }) confirmationMessage: any;
  @ViewChild(ToastMessageComponent, { static: true }) msgUpdateToast: ToastMessageComponent;
  @ViewChild('p') paginator: Paginator;
  @ViewChild('range') rangeCalendar: DateRangeFilterComponent;
  @Input() showSelection: any;
  sortColumn = 'userName';
  sortOrder = 'desc';
  @Input() userList: any[];

  param = {
    pageNo: 1,
    pageSize: 15,
    sortField: ''
  };
  @Input() tableCountData: any = {
    userCount: 0,
    totalRecords: 0
  };
  multiSortMeta: any = [];
  multiSortMetaDup: any = [];
  resetTable: any;
  @ViewChild(Table, { static: true }) tableComponent: Table;
  manageUserListForm: UntypedFormGroup;
  toggle = false;
  rolesDropdownData = [];
  selectedOptions = [];
  getOrgId: any;
  roleId = [];
  defaultRoleIds = [];
  filterClicked = false;
  isBusinessUnit = false;
  filterBtnDisableForFirstName = false;
  filterBtnDisableForLastName = false;
  filterBtnDisableForEmail = false;
  filterSelectedDate: any;
  isClickedClearBtn = false;
  externalAccess = false;
  stateVal: any;
  storeClientInfo: any;
  shellConstant = ShellConstant;
  hasUserAdminAccess = true;
  isManageUserComponent = true;
  displaySetupNewUser = true;
  showSelectedcount: any = '';
  statusSelectedOptions = [];
  updatedStatusOptions: any = [];
  userBUData: any = [];
  isSortingChanged = false;
  defaultSortDataField = {field: 'updatedDate', order: -1};
  sortClearButtonReset = false;

  constructor(public fb: UntypedFormBuilder, private manageUserService: ManageUsersService, public messageService: MessageService, @Inject('commonConstants') public commonConstants,
              private router: Router, private r: ActivatedRoute, public addlinkService: AddlinkService, public customSortService: CustomSortService,
              private headerService: HeaderService, private localStrService: LocalStorageService, public sharedService: SharedService, public el: ElementRef, public requestListService: RequestListService) {
    this.manageUserService.editMode = false;
    this.manageUserService.getUserID = '';
    this.getSaveClientMessage();
    /* Assigning Selected Client data to Header from sessionStorage for State management*/
    this.stateVal = this.sharedService.getStateVal();
    if (this.stateVal) {
      this.sharedService.clientInfo.next(this.stateVal.clientObj);
    }

    /* Navigate to respective page based on the selected client access */
    this.sharedService.selectedClientInfo.subscribe(clientInfo => {
      if (this.isManageUserComponent && clientInfo && !this.sharedService.checkEntitlements(entitlementConst.navUUID.manageUser.uuid, '', '')) {
        if (this.sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.ruleSummary.uuid, entitlementConst.navUUID.accountSummary.uuid, '')) {
          this.sharedService.loadDataTrackValue('client_redirect');
          /* If client is selected and the selected client does not have access to User Admin, then navigate to Workbench */
          this.isManageUserComponent = false;
          this.router.navigate(['ems/workbench']);
        } else if (this.sharedService.checkEntitlements(entitlementConst.navUUID.manageClient.uuid, '', '')) {
          this.sharedService.loadDataTrackValue('client_redirect');
          /* If client is selected and the selected client does not have access to User Admin but has access to Client Admin, then navigate to Manage Clients */
          this.isManageUserComponent = false;
          this.router.navigate(['ems/admin/manage-clients']);
        } else if (this.sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.instrumentsSummary.uuid, '', '')) {
          this.sharedService.loadDataTrackValue('client_redirect');
          this.isManageUserComponent = false;
          this.router.navigate(['/ems/emtr/instrument-groups/instrument-group-list']);
        }
      }
    });
  }

  ngOnInit() {
    this.manageUserListForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      emailAddresses: [''],
      userType: [''],
      roles: [''],
      status: [''],
      businessUnit: [''],
      updatedDate: ['']
    });
    this.pageObject.pageNo = this.stateVal && this.stateVal.pageObj.pageNo ? this.stateVal.pageObj.pageNo : 0;
    this.pageObject.pageSize = this.stateVal && this.stateVal.pageObj.pageSize ? this.stateVal.pageObj.pageSize : 15;
    this.stateVal && this.stateVal.multiSortMeta ? this.multiSortMeta = this.stateVal.multiSortMeta : this.multiSortMeta.push(this.defaultSortDataField);
    if (this.paginator) {
      this.paginator.totalRecords = this.stateVal ? this.stateVal.totalElem : 0;
    }
    this.customSortService.sortArr = [];
    this.resetTable = true;

    /* If logged-in user has only RBS BU access then the user should not be displayed with Setup New User button */
    const userPolicyBUValue = this.sharedService.userBUList;
    if (userPolicyBUValue && userPolicyBUValue.length === 1 && userPolicyBUValue[0] === this.getUsersConstant.rbsBUText) {
      this.displaySetupNewUser = false;
    }

    this.currentOrgSubscription = this.addlinkService.clientInformation.subscribe(clientInfo => {
      if (clientInfo !== null) {
        this.storeClientInfo = clientInfo;
        this.getOrgId = clientInfo && clientInfo.orgId;
        this.businessUnit = clientInfo && clientInfo.businessUnit;
        this.hasUserAdminAccess = this.sharedService.checkEntitlements(entitlementConst.navUUID.manageUser.uuid, '', '');
        clientInfo.externalAccess && clientInfo.externalAccess === 'Y' ? this.externalAccess = true : this.externalAccess = false;
        this.pageObject.pageNo = 1;
        if (this.sharedService.isClientChanged) {
          if (this.stateVal && this.stateVal.filterObj.custId) {
            this.stateVal.filterObj.custId = clientInfo.custId;
          }
          this.pageObject.pageNo = 1;
          this.pageObject.pageSize = 15;
          this.sharedService.stateValues.pageObj.pageNo = 1;
          this.sharedService.stateValues.pageObj.pageSize = 15;
          this.sharedService.stateValues.filterClicked = false;
          this.filterClicked = this.toggle = false;
          this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
          if (this.stateVal) {
            this.stateVal.pageObj.pageNo = 1;
            this.stateVal.pageObj.pageSize = 15;
          }
          this.sharedService.tableDropdownVal.next(this.stateVal ? this.stateVal.pageObj.pageSize : 15);
          this.stateVal = this.sharedService.getStateVal();
        }
        if (!this.resetTable) {
          this.multiSortMeta = [this.defaultSortDataField];
          this.customSortService.resetTable(this.defaultSortDataField, this.tableComponent);
        }

        /* Disabling the BU filter when client is selected in global client selector */
        if (clientInfo.businessUnit !== '') {
          this.manageUserListForm.get('businessUnit').disable();
        } else {
          this.manageUserListForm.get('businessUnit').enable();
        }

        /* Assigning filter icon value from sessionStorage for State management*/
        if (this.stateVal && this.stateVal.filterClicked) {
          this.toggle = this.stateVal.filterClicked;
          this.pageObject.pageNo = this.stateVal && this.stateVal.pageObj.pageNo ? this.stateVal.pageObj.pageNo : 0;
          this.manageUserListForm.markAsDirty();
          this.filterClicked = this.stateVal.filterClicked;
        }
      }
    });

    this.getUsersConstant.manageUserPage.manageUserFilter.status.forEach((item) => this.statusSelectedOptions.push(item.value));
    this.getUsersConstant.manageUserPage.manageUserFilter.userType.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
    this.updatedStatusOptions = [...this.statusSelectedOptions];
    const index =  this.updatedStatusOptions.indexOf('Inactive');
    if (index > -1) {
      this.updatedStatusOptions.splice(index, 1);
    }
    const newObj = {label: 'All', value: 'All'};
    const userTypeObj = {label: 'External', value: 'External'};
    this.manageUserListForm.patchValue({userType: this.businessUnit !== 'DBS' ? newObj : userTypeObj, status: this.updatedStatusOptions, businessUnit: newObj});
    this.onChange();
  }

  ngOnDestroy() {
    this.isManageUserComponent = false;
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
    this.sharedService.isValueObject = false;
    this.manageUserService.clearMessage();
    this.sharedService.updatedDate.next('');
    this.sharedService.requestStatusFilterObj.fromDate = '';
    this.sharedService.requestStatusFilterObj.toDate = '';
    this.sharedService.tableDropdownVal.next(15);
    /* Clearing sessionStorage value for other routes for State management*/
    if (this.router.routerState.snapshot.url.indexOf('manage-users') === -1) {
      this.sharedService.clearLocalStorage();
    }
  }

  getRoleIDAndName() {
      this.manageUserService.getRolesIdsAndDesc().subscribe(data => {
        if (data !== null) {
          data.data.roles.forEach(roles => {
            const obj = {label: roles.roleDesc, value: {roleDesc: roles.roleDesc , roleId: roles.roleId}};
            this.rolesDropdownData.push(obj);
            this.rolesDropdownData.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
            if (this.roleId.indexOf(roles.roleId) === -1) {
              this.roleId.push(roles.roleId);
            }
          });
          this.defaultRoleIds = this.roleId;
          if (data.data.businessUnits !== null) {
            this.isBusinessUnit = true;
          }
        }
        this.rolesDropdownData.forEach((item) => this.selectedOptions.push(item.value));
        this.manageUserListForm.patchValue({roles: this.selectedOptions});
        this.getUsers();
        if (data !== null) {
        this.userBUData = [];
        data.data.businessUnits.forEach(BUvalue => {
          const add = { label: BUvalue, value: BUvalue };
          this.userBUData.push(add);
          this.userBUData.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
        });
        const newObj = { label: 'All', value: 'All' };
        if (this.userBUData.length > 1) {
          this.userBUData.unshift(newObj);
        } else {
          /* If User has access to only 1 businessUnit,then populate the BU field and disable the BU */
          this.manageUserListForm.get('businessUnit').setValue(this.userBUData[0]);
          this.manageUserListForm.get('businessUnit').disable();
        }
      }
      });
  }

  onChange() {
    this.manageUserListForm.get('roles').valueChanges.subscribe(value => {
      if (value) {
        this.sharedService.isValueObject = true;
        this.roleId = [];
        value.forEach(data => {
          this.roleId.push(data.roleId);
        });
      }
    });
    this.manageUserListForm.get('firstName').valueChanges.subscribe(value => {
      if (value && value.length < 2) {
        this.filterBtnDisableForFirstName = true;
      } else {
        this.filterBtnDisableForFirstName = false;
      }
    });
    this.manageUserListForm.get('lastName').valueChanges.subscribe(value => {
      if (value && value.length < 2) {
        this.filterBtnDisableForLastName = true;
      } else {
        this.filterBtnDisableForLastName = false;
      }
    });
    this.manageUserListForm.get('emailAddresses').valueChanges.subscribe(value => {
      if (value && value.length < 2) {
        this.filterBtnDisableForEmail = true;
      } else {
        this.filterBtnDisableForEmail = false;
      }
    });
    this.manageUserListForm.get('status').valueChanges.subscribe(value => {
      if (value && value.length === 0) {
        this.manageUserListForm.patchValue({ status: this.statusSelectedOptions });
      }
    });
  }

  setFormValues() {
    let custId = '';
    if (this.localStrService.getProfile()) {
      const profile = JSON.parse(this.localStrService.getProfile());
      custId = profile.custId;
    }
    const localUpdatedFormValues = this.manageUserListForm.getRawValue();
    const fromDate = [];
    if (this.filterSelectedDate !== undefined && this.filterSelectedDate !== '' && this.filterSelectedDate !== null) {
      this.filterSelectedDate.forEach(date => {
        fromDate.push(date);
      });
    }
    const isRoleAll = this.rolesDropdownData.every((val, index) => val.value === localUpdatedFormValues.roles[index]);
    const isStatusAll = (this.statusSelectedOptions.length === localUpdatedFormValues.status.length && this.statusSelectedOptions.every(val => localUpdatedFormValues.status.indexOf(val) !== -1)) ;
    const filterObj: any = {
      businessUnit: this.isBusinessUnit ? localUpdatedFormValues.businessUnit.value : '',
      custId: custId ? custId : this.stateVal && this.stateVal.clientObj.custId ? this.stateVal.clientObj.custId : '',
      email: localUpdatedFormValues.emailAddresses ? localUpdatedFormValues.emailAddresses : null,
      firstName: localUpdatedFormValues.firstName ? localUpdatedFormValues.firstName : null,
      fromDate: fromDate.length > 0 ? moment(fromDate[0]).format('MM/DD/YYYY') : '',
      lastName: localUpdatedFormValues.lastName ? localUpdatedFormValues.lastName : null,
      roleIds: this.roleId ? (isRoleAll ? ['All'] : this.roleId) : [],
      status: isStatusAll ? localUpdatedFormValues.status = ['All'] : localUpdatedFormValues.status,
      toDate: fromDate.length > 0 ? moment(fromDate[1]).format('MM/DD/YYYY') : '',
      userType: localUpdatedFormValues.userType.value,
      roles: localUpdatedFormValues.roles
    };
    return filterObj;
  }

  getUsers() {
    let custId = '';
    let businessUnit = '';
    if (this.localStrService.getProfile()) {
      const profile = JSON.parse(this.localStrService.getProfile());
      custId = profile.custId;
      businessUnit = profile.businessUnit;
    }
    const filterObj = this.setFormValues();
    const filterParam = !this.sharedService.isClientChanged ? this.stateVal && this.stateVal.filterObj ? this.stateVal.filterObj : filterObj  : filterObj;
    if (this.sharedService.isClientChanged) {
      this.pageObject.pageNo = 1;
      this.pageObject.pageSize = 15;
    }
    this.sharedService.isClientChanged = false;
    const localUpdatedFormValues = this.manageUserListForm.getRawValue();
    const defaultObj: any = {
      businessUnit: this.isBusinessUnit ? custId ? businessUnit : this.userBUData.length === 1 ? this.userBUData[0].value : 'All' : '',
      custId: custId ? custId : '',
      email: null,
      firstName: null,
      fromDate: '',
      lastName: null,
      roleIds: ['All'],
      status: this.updatedStatusOptions,
      toDate: '',
      userType: this.businessUnit !== 'DBS' ? 'All' : 'External',
    };

    if (this.hasUserAdminAccess) {
      if (this.filterClicked) {
        /* Update filter values from sessionStorage for State management*/
        this.updateformValues(localUpdatedFormValues, filterParam);
        this.manageUserService.getAllUsers(this.pageObject, custId, filterParam).subscribe(users => {
          this.getUsersSuccessResponse(users);
        });
      } else {
        /* Update filter values from sessionStorage for State management*/
        this.updateformValues(localUpdatedFormValues, defaultObj);
        this.manageUserService.getAllUsers(this.pageObject, custId, defaultObj).subscribe(users => {
          this.getUsersSuccessResponse(users);
        });
      }
    }

    this.rowsDisplay = this.getUsersConstant.manageUserPage.defaultRecordSize;
  }

   getUsersSuccessResponse(users) {
    if (users.data !== null) {
      this.userList = users.data.content;
      this.totalUsers = this.sharedService.totalElementsWithCommas(users.data.totalElements);
      this.paginationCount = users.data.totalElements;
      this.resetTable = false;
      /* Updating State Values for paginator to support page Val for State management*/
      this.sharedService.stateValues.totalElem = this.paginationCount;
      this.sharedService.stateValues.pageObj.pageNo = this.pageObject.pageNo;
      this.sharedService.stateValues.pageObj.pageSize = this.pageObject.pageSize;
      this.sharedService.stateValues.multiSortMeta = this.multiSortMeta;
      this.sharedService.stateValues.filterClicked = this.filterClicked;
      this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
      this.stateVal = this.sharedService.getStateVal();
    }
}

  updateformValues(formControls, formVals) {
    this.sharedService.updatedDate.next(formVals.fromDate ? {name: 'Require Indexing', fromDate: formVals.fromDate, toDate: formVals.toDate } : '');
    this.manageUserListForm.patchValue({
      userType: {label: formVals.userType ? formVals.userType : 'All', value: formVals.userType ? formVals.userType : 'All'},
      status:  formVals.status ? formVals.status.length > 0 && formVals.status.indexOf('All') >= 0 ? this.statusSelectedOptions : formVals.status : this.statusSelectedOptions,
      businessUnit: {label: formVals.businessUnit ? formVals.businessUnit : 'All', value: formVals.businessUnit ? formVals.businessUnit : 'All'},
      firstName : formVals.firstName,
      lastName: formVals.lastName,
      emailAddresses: formVals.email,
      roles: formVals.roles ? formVals.roles.length > 0 && formVals.roles.indexOf('All') >= 0 ? this.selectedOptions : formVals.roles : this.selectedOptions,
    });
  }
  customSort(event) {
    if (event.multiSortMeta && this.pageObject.sortField !== this.customSortService.customSort(event)) {
      this.multiSortMetaDup = this.multiSortMeta;
      this.multiSortMeta = event.multiSortMeta;
      const sortArr = this.customSortService.customSort(event);
      const checkStateVal = this.sharedService.getStateVal();
      if (checkStateVal && JSON.stringify(event.multiSortMeta) !== JSON.stringify(checkStateVal.multiSortMeta)) {
        this.stateVal.pageObj.pageNo = 1;
      }
      this.isSortingChanged = (JSON.stringify(event.multiSortMeta) === JSON.stringify([this.defaultSortDataField])) ? false : true;
      /* Assigning updated page number from sessionStorage for State management*/
      if (this.stateVal && this.stateVal.pageObj.pageNo) {
        setTimeout(() => {
          if (this.paginator) {
            this.paginator.totalRecords = this.stateVal ? this.stateVal.totalElem : 0;
            this.paginator.changePage(this.stateVal.pageObj.pageNo > 1 ? this.stateVal.pageObj.pageNo - 1 : 0);
          }
        }, 0);
      }
      this.pageObject = {
        pageNo: this.pageObject.pageNo,
        pageSize: this.pageObject.pageSize,
        sortField: sortArr
      };
      if (this.roleId.length === 0) {
        this.getRoleIDAndName();
      } else {
        if ((this.multiSortMetaDup === this.multiSortMeta) || this.multiSortMetaDup.length > 1 || this.sortClearButtonReset) {
          this.clearSuccessAndErrorMessages();
          this.getUsers();
          this.sortClearButtonReset = false;
        }
      }
    }
  }

  editUser(userId) {
    this.manageUserService.editMode = true;
    this.manageUserService.getUserID = userId;
    this.manageUserService.getRoles(userId, '').subscribe(success => {
      this.manageUserService.roles = success.data;
      this.sharedService.isValueObject = true;
      this.router.navigate(['./create-user'], {relativeTo: this.r});
    }, error => {
      this.manageUserService.roles = null;
      this.router.navigate(['./create-user'], {relativeTo: this.r});
    });
  }

  getSaveClientMessage() {
    this.manageUserService.getMessage().subscribe((data) => {
      this.landingPageMsg = data.text;
      if (this.landingPageMsg && this.landingPageMsg !== null && this.landingPageMsg !== '') {
        setTimeout(() => {
          if (this.landingPageMsg) {
            this.msgUpdateToast.pushMessage('success', this.landingPageMsg);
          }
        }, 1);
      }
    });
  }

  paginate(e) {
    if (this.pageObject.pageNo !== e.page + 1) {
      const clientName = JSON.parse(this.localStrService.getProfile());
      const pageVals: any = (this.storeClientInfo && this.storeClientInfo.clientName) !== (clientName && clientName.clientName) ? this.stateVal && this.stateVal.pageObj.pageNo ? this.stateVal.pageObj.pageNo : e.page + 1 : e.page + 1;
      this.pageObject.pageNo = pageVals;
      this.clearSuccessAndErrorMessages();
      // this.pageObject.sortField = this.param.sortField;
      this.getUsers();
    }
  }

  toggleFunction() {
    this.toggle = !this.toggle;
    /* Assigning filter value to sessionStorage for State management*/
    this.sharedService.stateValues.filterClicked = this.toggle;
    this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
  }

  clickedClearButton(resetSortFromUI?) {
    this.resetForm(resetSortFromUI);
    this.sharedService.tableDropdownVal.next(this.pageObject.pageSize);
    if (this.paginationCount && this.paginationCount > this.sharedConstant.defaultTableRecords) {
      this.paginator.changePage(0);
    }
    this.sharedService.sendErrorMessage('');
    if (!this.sortClearButtonReset) {
      this.getUsers();
    }
  }

  resetForm(resetSortFromUI?) {
    this.manageUserListForm.reset();
    this.roleId = this.defaultRoleIds;
    const newObj = {label: 'All', value: 'All'};
    if (resetSortFromUI) {
      this.multiSortMeta = [this.defaultSortDataField];
      this.sortClearButtonReset = true;
    }
    this.manageUserListForm.patchValue({userType: newObj, status: this.updatedStatusOptions, businessUnit: newObj, roles: this.selectedOptions});
    if (this.toggle) {
      this.isClickedClearBtn = true;
      this.rangeCalendar.clearSelection();
    }
    this.pageObject.pageNo = 1;
    this.filterClicked = false;
    this.isClickedClearBtn = false;
  }

  ngAfterViewChecked(): void {
    const elementRef = this.el.nativeElement.querySelectorAll('.p-multiselect-label')[0];
    this.sharedService.setMultiSelectDefaultLabel(elementRef, this.manageUserListForm, 'roles', this.selectedOptions.length);
    const elementRefStatus = this.el.nativeElement.querySelectorAll('.p-multiselect-label')[1];
    this.sharedService.setMultiSelectDefaultLabel(elementRefStatus, this.manageUserListForm, 'status', this.statusSelectedOptions.length);
    /* Making the form dirty during State management. As the changes is overridden by angular, hence making form dirty again once View check is completed */
  }

  applyFilter() {
    /* Set the selected date value to the manageUserListForm before hitting the Filter API */
    if (this.rangeCalendar) {
      this.rangeCalendar.applyToDate();
      this.getSelectedDate(this.rangeCalendar.dateRangeForm.get('dateSelected').value);
    }

    this.pageObject.pageNo = 1;
    if (this.paginationCount && this.paginationCount > this.sharedConstant.defaultTableRecords) {
      this.paginator.changePage(0);
    }
    this.filterClicked = true;
    if (this.stateVal) {
      this.stateVal.pageObj.pageSize = 15;
      this.stateVal.pageObj.pageNo = 1;
      this.stateVal.filterObj = this.setFormValues();
    }
    this.clearSuccessAndErrorMessages();
    this.getUsers();
  }

  getSelectedDate(date) {
    this.filterSelectedDate = date;
    this.manageUserListForm.get('updatedDate').setValue(this.filterSelectedDate);
    if (this.filterSelectedDate) {
      this.manageUserListForm.markAsDirty();
    }
  }

  clearSuccessAndErrorMessages() {
    this.messageService.clear();
    this.sharedService.clearErrorMessage();
  }
}
