import { Component, OnInit } from '@angular/core';
import {ShellConstant} from '@shell_components/constants/shellConstants';
import { SharedService } from '@ems/shared';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  shellConstant = ShellConstant;
  hideUnauthorized: any;
  accessDenied: any;
  constructor(public sharedService: SharedService) {
    /* Handled Unauthorized Page for InitialLoad/Request Error Scenarios */
    this.sharedService.requestFailureStatus.subscribe(value => {
      this.hideUnauthorized = value;
    });

    this.sharedService.accessDeniedStatus.subscribe(value => {
      this.accessDenied = value;
    });
  }

  ngOnInit() { }

}
