<ems-toast-message #toastMessage></ems-toast-message>

<sdps-notification type="error" class="sdps-m-top_none" *ngIf="subHeaderSchErrorMsg.length>0 || filterBtnDisable || filterBtnDisableForEmpName || (requestListHeaderForm.get('employeeId').value && requestListHeaderForm.get('employeeId').invalid) || (requestListHeaderForm.get('employeeName').value && requestListHeaderForm.get('employeeName').invalid) || (requestListHeaderForm.get('acctNumber').value && requestListHeaderForm.get('acctNumber').invalid) || (requestListHeaderForm.get('masterAccountNbr').value && requestListHeaderForm.get('masterAccountNbr').invalid) || filterBtnDisableForMastAcctNum || filterBtnDisableForAcctNum">
  <div class="sdps-notification__text">
    <p [innerText]="getAdlConst.pleaseCorrectFollowingError"></p>
    <ul class="sdps-navigation--horizontal__list error-list">
      <li *ngFor='let msg of subHeaderSchErrorMsg' [innerHTML]="msg"></li>
      <li *ngIf="(requestListHeaderForm.get('employeeId').value && requestListHeaderForm.get('employeeId').invalid)"
          [innerHTML]="getAdlConst.textFieldErrorMsg.employeeIdErrorMsg"></li>
      <li *ngIf="filterBtnDisable || filterBtnDisableForEmpName"
          [innerHTML]="getSharedConstant.textFieldErrorMsg.minCharLimitErrorMsg"></li>
      <li *ngIf="(requestListHeaderForm.get('acctNumber').value && requestListHeaderForm.get('acctNumber').invalid) || (requestListHeaderForm.get('masterAccountNbr').value && requestListHeaderForm.get('masterAccountNbr').invalid)"
          [innerHTML]="getAdlConst.textFieldErrorMsg.invalidCharacterEnteredNumericValue"></li>
      <li *ngIf="(requestListHeaderForm.get('employeeName').value && requestListHeaderForm.get('employeeName').invalid)" [innerHTML]="getAdlConst.textFieldErrorMsg.invalidCharacterForName"></li>
      <li *ngIf="filterBtnDisableForAcctNum" [innerHTML]="getAdlConst.textFieldErrorMsg.accountNumberShouldContain8Digits"></li>
      <li *ngIf="filterBtnDisableForMastAcctNum" [innerHTML]="getAdlConst.textFieldErrorMsg.masterAcctNumShouldContain8Digits"></li>
    </ul>
  </div>
</sdps-notification>

<!-- Header -->
<div>
  <div>
    <sdps-notification type="error" class="sdps-m-top_none" *ngIf="showServiceError">
      <div class="sdps-notification__text">
        <p [innerHTML]="requestListService.showRequestListMessage"></p>
      </div>
      <button class="sdps-button sdps-messaging__close sch sch-x schwab-cross-icon sdps-p-top_x-small" type="button"
              (click)="showServiceError = false"></button>
    </sdps-notification>
  </div>
  <h2 class="page-title sdps-m-bottom_large sdps-display-inline-block" [innerText]="getAdlConst.requestListHeaderLabel"></h2>

  <span class="sdps-m-horizontal_large client-selection-indicator" *ngIf="clientName !== null"
        [ngClass]="this.externalAccess ? 'external-access' : (addlinkService.clientInformation | async)?.businessUnit | lowercase"
        [innerHTML]="clientName">
    </span>

  <div class="sdps-pull-right sdps-hide">
        <span class="centered-container">
            <span class="ems-icon pi pi-cog"></span>
            <span class="customize-view-text" [innerText]="getAdlConst.customizeViewTxt"></span>
        </span>
  </div>
</div>

<!-- Action Bar -->
<div class="sdps-mosaic-tile sdps-m-bottom_large sdps-p-around_small centered-container action-bar">

    <span class="centered-container">
     <div class="take-action-container sdps-p-right_small"
           *ngIf="showActionItems && this.sharedService.isInternalApp()">
        <p-dropdown #dropdown [placeholder]="getAdlConst.takeActionMenuMsg.takeActionTxt" [(ngModel)]="selectedAction" [options]="takeActionMenuList" [disabled]="!enableResubmit()">
          <ng-template let-list pTemplate="list">
            <div class="menu-item" (click)="takeActionPopup($event, list.value)">
              <em class="sch-x-circle sdps-pull-left sdps-p-right_small" [ngClass]="list.value === getAdlConst.takeActionMenuMsg.denyTxt ?  'sch-x-circle' : list.value === getAdlConst.takeActionMenuMsg.resubmitTxt ? 'sch-revert' : 'sch-check'"></em>
              <span class="menu-list-name">{{list.value}}</span>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <button type="button" class="buttons"
                *ngIf="showActionItems && !this.sharedService.isInternalApp()"
                [disabled]="!enableResubmit()" (click)="takeActionPopup($event, getAdlConst.takeActionMenuMsg.resubmitTxt)" [innerText]="getAdlConst.takeActionMenuMsg.resubmitTxt"></button>
      <button type="button" class="buttons sdps-m-right_small" (click)="bulkSubmit()"
                *ngIf="showActionItems && !bulkUploadBtnHide">{{getAdlConst.addLkGlobal.bulkUploadAddlink}}</button>
    </span>

  <span class="centered-container">

        <span class="sdps-m-horizontal_small">{{getAdlConst.totalTxt}} {{pageObject.totalElements}}</span>

        <ng-container *ngIf="paginationCount && paginationCount > getSharedConstant.defaultTableRecords">
          <span class="divider"></span>
  
          <span class="sdps-m-left_small" [innerText]="getAdlConst.showTxt"></span>
  
          <div class="sdps-dropdown sdps-dropdown--lightGrey sdps-display-inline-block ems-dropdown">
              <select class="sdps-dropdown__select sdps-dropdown--lightGrey__select" [(ngModel)]="pageSize" [id]="pageSize"
                      (change)="refreshDataOnPageSizeChange()">
                  <option value="15" selected>15</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
              </select>
              <span class="sdps-dropdown__icon sdps-dropdown--lightGrey__icon">
                  <span aria-hidden="true" class="sch-caret-down"></span>
              </span>
          </div>
  
          <span class="sdps-m-left_small" [innerText]="getAdlConst.recordsTxt"></span>
        </ng-container>

    </span>

</div>

<!-- Requests List -->
<form [formGroup]="requestListHeaderForm">
  <p-table #requestListTable [sortOrder]="sortOrder === 'desc' ? -1 : 1" [sortField]="sortColumn" [columns]="filteredRequestColumns" (onRowExpand)="onRowExpand()" (onRowCollapse)="onRowCollapse()"
           [value]="requests" dataKey="requestId" class="common-data-table request-list-table filtered-table custom-table-filter"  [expandedRowKeys]="expandedRows"
           [(selection)]="selectedRequests" [lazy]="true" (onLazyLoad)="paginator.changePage(0)" [lazyLoadOnInit]="false">
    <ng-template pTemplate="header" let-rowData let-expanded="expanded" let-columns>

      <tr [ngClass]="this.toggle ? 'no-border-bottom' : ''">

        <th class="w-6-per">
          <div class="sdps-grid-fluid">
            <div class="col-3 sdps-m-around_none sdps-p-around_none">
              <a (click)="expandAll()" class="cursor">
                <em [ngClass]="showHeader ? 'pi pi-caret-down' : 'pi pi-caret-right'"></em>
              </a>
            </div>
            <div class="col-3 sdps-m-around_none sdps-p-around_none">
              <a (click)="sharedService.gutTracking('adobe-fl_requestlistfilter|fireonce'); toggleFctn()" class="cursor">
                <em class="pi pi-filter"></em>
              </a>
            </div>
            <div class="col-3 sdps-m-around_none">
              <span class="ems-checkbox ems-checkbox-medium" *ngIf="sharedService.checkEntitlements(entitlementConst.navUUID.accountLinking.uuid, '', '')">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </span>
            </div>
          </div>
        </th>

        <th *ngFor="let col of columns" [pSortableColumn]="col.field"
            (click)="sortColumnByField($event, col.field)"
            [ngClass]="[(col.field === getAdlConst.requestListMainHeaderFieldTxt.client && !sharedService.checkEntitlements(entitlementConst.navUUID.addlnkAdminResource.uuid,'','')) || (!sharedService.checkEntitlements(this.entitlementConst.navUUID.accountLinking.uuid, '', '') && col.field === getAdlConst.requestListMainHeaderFieldTxt.employeeId) ? 'sdps-hide': '', paginationCount && paginationCount > 1 || filterClicked ? 'pointer-events-auto' : 'pointer-events-none' ]">

          {{col.header}}
          <p-sortIcon class="cursor" [field]="col.field" [attr.arialabel]="commonConstants.sorting.sortText"
                      [attr.ariaLabelDesc]="commonConstants.sorting.descText"
                      [attr.ariaLabelAsc]="commonConstants.sorting.ascText" *ngIf="paginationCount && paginationCount > 1 || filterClicked"></p-sortIcon>
        </th>
        <th [ngClass]="(businessUnitClass === 'RBS' && this.toggle) ? 'w-5-per' : 'w-200-px' "></th>
        </tr>

      <tr *ngIf="this.toggle" class="request-list-filtered-row" [ngClass]="this.toggle ? 'no-border-top' : ''">
        <th></th>
        <th *ngFor="let col of columns ; let i = index;" [ngClass]="{'sdps-hide': (!sharedService.checkEntitlements(this.entitlementConst.navUUID.accountLinking.uuid, '', '') && col.field === getAdlConst.requestListMainHeaderFieldTxt.employeeId)}">
          <input pInputText formControlName="{{col.field}}" class="w-100-per" type="number" name="filter" (keydown.enter)="!(filterBtnDisable || (requestListHeaderForm.get('employeeId').value && requestListHeaderForm.get('employeeId').invalid || (requestListHeaderForm.get('acctNumber').value && requestListHeaderForm.get('acctNumber').invalid) || (requestListHeaderForm.get('masterAccountNbr').value && requestListHeaderForm.get('masterAccountNbr').invalid)))? clickedFilterBtn() : ''" onkeydown="return (event.keyCode !== 13);"
                 *ngIf="col.enum === 'textbox' && col.field === getAdlConst.requestListMainHeaderFieldTxt.requestId" onkeydown="return (event.keyCode !== 69 && event.keyCode !== 13)"
                 [ngClass]="{'disabled': (filterState === 'disable' && col.field !== getAdlConst.requestListMainHeaderFieldTxt.requestId)}">
          <input pInputText formControlName="{{col.field}}" class="w-100-per" type="text" name="filter" onkeypress="if(event.which<48 || event.which>57) return false;" (keydown.enter)="!(filterBtnDisable || filterBtnDisableForEmpName || (requestListHeaderForm.get('employeeId').value && requestListHeaderForm.get('employeeId').invalid) || (requestListHeaderForm.get('acctNumber').value && requestListHeaderForm.get('acctNumber').invalid) || (requestListHeaderForm.get('masterAccountNbr').value && requestListHeaderForm.get('masterAccountNbr').invalid)) ? clickedFilterBtn() : ''" onkeydown="return (event.keyCode !== 13);"
                 *ngIf="col.enum === 'textbox' && col.field === getAdlConst.requestListMainHeaderFieldTxt.masterAccountNbr" emsNumberPatterncheck
                 [ngClass]="{'disabled': (filterState === 'disable' && col.field !== getAdlConst.requestListMainHeaderFieldTxt.requestId), 'invalidField': (requestListHeaderForm.get('masterAccountNbr').value && requestListHeaderForm.get('masterAccountNbr').invalid) || filterBtnDisableForMastAcctNum}" [maxlength]="8">
          <input pInputText formControlName="{{col.field}}" class="w-100-per" type="text" name="filter" onkeypress="if(event.which<48 || event.which>57) return false;" (keydown.enter)="!(filterBtnDisable || filterBtnDisableForEmpName || (requestListHeaderForm.get('employeeId').value && requestListHeaderForm.get('employeeId').invalid) || (requestListHeaderForm.get('acctNumber').value && requestListHeaderForm.get('acctNumber').invalid) || (requestListHeaderForm.get('masterAccountNbr').value && requestListHeaderForm.get('masterAccountNbr').invalid)) ? clickedFilterBtn() : ''" onkeydown="return (event.keyCode !== 13);"
                 *ngIf="col.enum === 'textbox' && col.field === getAdlConst.requestListMainHeaderFieldTxt.acctNumber" emsNumberPatterncheck
                 [ngClass]="{'disabled': (filterState === 'disable' && col.field !== getAdlConst.requestListMainHeaderFieldTxt.requestId), 'invalidField': (requestListHeaderForm.get('acctNumber').value && requestListHeaderForm.get('acctNumber').invalid) || filterBtnDisableForAcctNum}" [maxLength]="8">
          <input pInputText formControlName="{{col.field}}" class="w-100-per" type="text" name="employeeId" [maxLength]="35" emsEmployeeIdPatterncheck
                 *ngIf="col.enum === 'textbox' && col.field === getAdlConst.requestListMainHeaderFieldTxt.employeeId" (keydown.enter)="!(filterBtnDisable || filterBtnDisableForEmpName || (requestListHeaderForm.get('employeeId').value && requestListHeaderForm.get('employeeId').invalid) || (requestListHeaderForm.get('acctNumber').value && requestListHeaderForm.get('acctNumber').invalid) || (requestListHeaderForm.get('masterAccountNbr').value && requestListHeaderForm.get('masterAccountNbr').invalid)) ? clickedFilterBtn() : ''" onkeydown="return (event.keyCode !== 13);"
                 [ngClass]="{'disabled': (filterState === 'disable' && col.field !== getAdlConst.requestListMainHeaderFieldTxt.employeeId), 'invalidField': (filterBtnDisable || (requestListHeaderForm.get('employeeId').value && requestListHeaderForm.get('employeeId').invalid))}">
          <input pInputText formControlName="{{col.field}}" class="w-100-per" type="text" name="employeeName" [maxLength]="50" emsNamePatternCheck
                 *ngIf="col.enum === 'textbox' && col.field === getAdlConst.requestListMainHeaderFieldTxt.employeeName" (keydown.enter)="!(filterBtnDisable || filterBtnDisableForEmpName || (requestListHeaderForm.get('employeeId').value && requestListHeaderForm.get('employeeId').invalid) || (requestListHeaderForm.get('employeeName').value && requestListHeaderForm.get('employeeName').invalid) || (requestListHeaderForm.get('acctNumber').value && requestListHeaderForm.get('acctNumber').invalid) || (requestListHeaderForm.get('masterAccountNbr').value && requestListHeaderForm.get('masterAccountNbr').invalid)) ? clickedFilterBtn() : ''" onkeydown="return (event.keyCode !== 13);"
                 [ngClass]="{'disabled': (filterState === 'disable' && col.field !== getAdlConst.requestListMainHeaderFieldTxt.employeeName), 'invalidField': (filterBtnDisableForEmpName || (requestListHeaderForm.get('employeeName').value && requestListHeaderForm.get('employeeName').invalid))}">
          <div class="timePickerForm addlink-request-input" *ngIf="col.enum === 'dropdown' && col.field === getAdlConst.requestListMainHeaderFieldTxt.action">
            <p-dropdown [options]="getAdlConst.actionData" placeholder=" " formControlName="{{col.field}}"
                        class="sdps-p-top_small editableTimepicker request-list-header-dropdown" optionLabel="label"
                        [disabled]="filterState === 'disable'">
            </p-dropdown>
          </div>
          <div *ngIf="col.enum === 'multiselect' && (col.field === getAdlConst.internalStatusTxt || col.field === getAdlConst.externalStatusTxt)"
               class="col-2 emtr-dropdown sdps-p-right_none sdps-m-top_xx-small request-list-filter request-list-multiselect-font-size request-list-filter-multiselect timePickerForm">
            <p-multiSelect appendTo="body" [options]="statusDropdownData" formControlName="{{col.field}}" [defaultLabel]="' ' "
                           [displaySelectedLabel]= "true" [maxSelectedLabels]= "1" [filterPlaceHolder]="'All'"
                           [filter]="true" [showHeader]="true" [disabled]="filterState === 'disable' || businessUnitClass ==='RBS'">
            </p-multiSelect>
          </div>
        </th>
        <th></th>
      </tr>

      <!-- filter row inputs -->
      <tr *ngIf="this.toggle" class="expanded-row-header">
        <th></th>
        <th [attr.colspan]="sharedService.isInternalApp() ? 8 : 7" class="sdps-p-around_none">
          <addlink-request-list-sub-header [onFilterClicked]="toggle" [disableForm]="filterState" (sendFilterObject)="applyFilter($event)"
                                           [expandedRowColumns]='filteredExpandedRowColumns' [isHidden]="isHidden" (subheaderValue)="subHeaderHasValue = $event" [businessRbs]="businessUnitClass"></addlink-request-list-sub-header>
        </th>
        <th *ngIf="this.toggle" class="sdps-p-left_small sdps-p-bottom_small apply-filter-box w-200-px">
            <button class="buttons button-filter"
            [disabled]="!requestListHeaderForm.dirty && !isSortingChanged && !subHeaderHasValue"
                    [innerHTML]="getAdlConst.requestListFilterButtons.clearAllButton" (click)="filterClicked? clickedClearBtn() : resetForm()"></button>
            <button class="buttons button-save button-filter" [disabled]="(filterBtnDisable || filterBtnDisableForEmpName || (requestListHeaderForm.get('employeeId').value && requestListHeaderForm.get('employeeId').invalid) || (requestListHeaderForm.get('employeeName').value && requestListHeaderForm.get('employeeName').invalid) || (requestListHeaderForm.get('acctNumber').value && requestListHeaderForm.get('acctNumber').invalid) || (requestListHeaderForm.get('masterAccountNbr').value && requestListHeaderForm.get('masterAccountNbr').invalid) || filterBtnDisableForAcctNum || filterBtnDisableForMastAcctNum)" #filterBtn
                    [innerHTML]="getAdlConst.requestListFilterButtons.applyFilterButton" (click)="clickedFilterBtn()"></button>
        </th>
        <th *ngIf="!this.toggle"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-expanded="expanded" let-columns="columns">

      <tr [pSelectableRow]="rowData">

        <td class="w-6-per">
          <div class="sdps-grid-fluid">
            <div class="col">
              <a [pRowToggler]="rowData" (click)="expanded ? showFilterHeaders(rowData) : showFilterHeaders(rowData)">
                <em [ngClass]="expanded ? 'pi pi-caret-down' : 'pi pi-caret-right'" ></em>
              </a>
            </div>
            <div class="col">
              <span class="ems-checkbox ems-checkbox-medium" *ngIf="showActionItems">
                <p-tableCheckbox *ngIf="isRequestActionable(rowData)" [value]="rowData"></p-tableCheckbox>
                <p-checkbox *ngIf="!isRequestActionable(rowData)" binary="true"
                            [disabled]="!isRequestActionable(rowData)"></p-checkbox>
              </span>
            </div>
          </div>
        </td>

        <td *ngFor="let col of columns; let i = index;" class="addlink-hide-overflow"
            [ngClass]="{'sdps-hide': (col.field === getAdlConst.requestListMainHeaderFieldTxt.client && !sharedService.checkEntitlements(entitlementConst.navUUID.addlnkAdminResource.uuid,'','')) || 
            (!sharedService.checkEntitlements(this.entitlementConst.navUUID.accountLinking.uuid, '', '') && col.field === getAdlConst.requestListMainHeaderFieldTxt.employeeId), 'w-13-per': (col.field === getAdlConst.internalStatusTxt || col.field === getAdlConst.externalStatusTxt)}">

                <span class="word-break w-13-per" *ngIf="col.field === getAdlConst.internalStatusTxt || col.field === getAdlConst.externalStatusTxt" title="{{rowData[col.field]}}">
                    <span *ngIf="rowData[col.field] === getAdlConst.statusLabel.complete || rowData[col.field] === getAdlConst.statusLabel.resolved"
                          class="ems-status sdps-p-around-xx-large ems-status-active"></span>
                    <span
                      *ngIf="rowData[col.field] === getAdlConst.statusLabel.onHold || rowData[col.field] === getAdlConst.statusLabel.validating || rowData[col.field] === getAdlConst.statusLabel.received"
                      class="ems-status sdps-p-around-xx-large ems-status-draft"></span>
                    <span
                      *ngIf="rowData[col.field] === getAdlConst.statusLabel.dbsActionNeeded || rowData[col.field] === getAdlConst.statusLabel.ineligibleRequest || rowData[col.field] === getAdlConst.statusLabel.validationMismatch || rowData[col.field] === getAdlConst.statusLabel.requestDenied"
                      class="ems-status sdps-p-around-xx-large ems-status-expired"></span>
                    <span *ngIf="rowData[col.field] === getAdlConst.statusLabel.requireIndexing"
                      class="ems-status sdps-p-around-xx-large ems-require-indexing"></span>
                  {{rowData[col.field]}}
                </span>
          <!-- Displaying employee name in a line if length is less than or equal to 10 -->
          <span
            *ngIf="col.field === getAdlConst.requestListMainHeaderFieldTxt.employeeName && ((rowData['employeeLastName'].length + rowData['employeeFirstName'].length) <= 10)">
            <span>
              <span class="sdps-display-inline-block"> {{rowData['employeeLastName']}}</span>
            </span>
            <span class="sdps-display-inline-block" *ngIf="rowData['employeeLastName'].length > 0 && rowData['employeeFirstName'].length > 0">,&nbsp;</span>
              <span class="sdps-display-inline-block"
                    *ngIf="rowData['employeeFirstName'] !== null && rowData['employeeFirstName'] !== ''">
                         {{rowData['employeeFirstName']}}
            </span>
          </span>
          <!-- Adding ellipsis after 20 characters -->
          <span *ngIf="col.field === getAdlConst.requestListMainHeaderFieldTxt.employeeName && ((rowData['employeeLastName'].length + rowData['employeeFirstName'].length)  > 10)" title="{{rowData['employeeLastName']}}, {{rowData['employeeFirstName']}}">
            <span class="sdps-datepicker__range">
              <span class="sdps-display-inline-block sdps-truncate--ellipses employeeNameWidth">
                {{rowData['employeeLastName']}}
              </span>
               <span class="sdps-display-inline-block" *ngIf="rowData['employeeLastName'].length > 0 && rowData['employeeFirstName'].length > 0">,</span>
            </span>
            <span *ngIf="rowData['employeeFirstName'] !== null && rowData['employeeFirstName'] !== ''"
                 class="sdps-truncate--ellipses employeeFirstNameWidth">
                        {{rowData['employeeFirstName']}}
            </span>
          </span>

          <span
            *ngIf=" col.field ===getAdlConst.requestListMainHeaderFieldTxt.client && rowData[col.field] !== null && sharedService.checkEntitlements(entitlementConst.navUUID.addlnkAdminResource.uuid,'','')"
            title=" {{rowData[col.field][getAdlConst.requestListMainHeaderFieldTxt.client]}}" class="word-break">
                    {{rowData[col.field]['clientName']}}
                </span>

          <span *ngIf="col.field !== 'actions' && col.field !== getAdlConst.internalStatusTxt && col.field !== getAdlConst.externalStatusTxt && col.field !== getAdlConst.requestListMainHeaderFieldTxt.employeeName && col.field !== 'masterAccountNbr'
                && col.field !== getAdlConst.requestListMainHeaderFieldTxt.client" title="{{rowData[col.field]}}">
                    {{rowData[col.field]}}
                </span>

          <span *ngIf="col.field === 'masterAccountNbr'" class="word-break master-account-width">
            {{rowData[col.field]}}
          </span>
        </td>
        <td *ngIf = "businessUnitClass === 'RBS' && this.toggle"></td>
        <td>
          <div class="action-menu-dropdown">
            <button type="button" class="sdps-p-around_xx-small"
                    (click)="showActionMenu=true; requestId=rowData[columns[0].field]; formData(rowData);"
                    [ngClass]="{'active-menu':requestId===rowData[columns[0].field]}">
              <em class="pi pi-ellipsis-h sdps-p-around_xx-small"></em>
            </button>
            <p-menu [model]="actionMenu" *ngIf="requestId===rowData[columns[0].field]"
                    class="menu-dropdown word-break" (click)="onActionMenuClick($event, rowData)"></p-menu>
          </div>
        </td>
      </tr>

    </ng-template>

    <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">

      <tr>

        <td></td>

        <td [attr.colspan]="columns.length" >

          <addlink-expanded-row [isHidden]='isHidden' [request]='rowData'
                                [expandedRowColumns]='dataRowListColumns' [businessRbs]="businessUnitClass">
          </addlink-expanded-row>

        </td>
        <td></td>
      </tr>

    </ng-template>

    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td *ngIf="!noRecordFiltermessage" [attr.colspan]="columns.length + 2" class="sdps-text-center" [innerHTML]="getAdlConst.addLkGlobal.noRecords"> </td>
        <td *ngIf="noRecordFiltermessage" [attr.colspan]="columns.length + 2" class="sdps-text-center" [innerHTML]= "noRecordFiltermessage"> </td>
      </tr>
    </ng-template>

  </p-table>
</form>

<p-paginator #paginator [rows]="pageObject.size" [alwaysShow]="paginationCount && paginationCount > getSharedConstant.defaultTableRecords ? true : false" [totalRecords]="paginationCount" (onPageChange)="paginate($event);"
             class="common-pagination"></p-paginator>

<div class="sdps-m-bottom_large"></div>


<addlink-remarks-modal #remarks></addlink-remarks-modal>
<addlink-edit-index-key-modal #editIndexKeyModal
                              (udpateIndexKeyRequest)="updateSingleRequest($event)"></addlink-edit-index-key-modal>
<addlink-resubmit-requests-modal #resubmitRequestsModal></addlink-resubmit-requests-modal>
<addlink-deny-requests-modal #denyRequestsModal></addlink-deny-requests-modal>
<addlink-edit-requests-modal #editRequestsModal
                             (udpateRequest)="updateSingleRequest($event)"></addlink-edit-requests-modal>
<addlink-client-central-notes #clientCentralNotes></addlink-client-central-notes>
