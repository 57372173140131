  <ems-toast-message #msgUpdateToast></ems-toast-message>

  <ems-service-error *ngIf="sharedService.errorMessage | async"></ems-service-error>
  <sdps-notification type="error" class="sdps-m-top_none" *ngIf="filterBtnDisableForEmail || filterBtnDisableForLastName || filterBtnDisableForFirstName || (manageUserListForm.get('firstName').value && (manageUserListForm.get('firstName').invalid)) || (manageUserListForm.get('lastName').value && (manageUserListForm.get('lastName').invalid))">
    <div class="sdps-notification__text">
      <p>Please correct following error.</p>
      <ul class="sdps-navigation--horizontal__list error-list">
        <li *ngIf="filterBtnDisableForEmail || filterBtnDisableForLastName || filterBtnDisableForFirstName"
            [innerHTML]="getUsersConstant.manageUserPage.managerUserFilterErrorMsgs.minCharLimitErrorMsg">
        </li>
        <li
        *ngIf="(manageUserListForm.get('firstName').value && (manageUserListForm.get('firstName').invalid)) || (manageUserListForm.get('lastName').value && (manageUserListForm.get('lastName').invalid))"
        [innerHTML]="getUsersConstant.manageClients.clientDetailsPage.errorMsg.invalidCharacterEnteredAlphabeticValue">
      </li>
      </ul>
    </div>
  </sdps-notification>

  <h2 class="page-title sdps-m-bottom_large sdps-display-inline-block"
      [innerHTML]="getUsersConstant.manageUserPage.manageUsers">
  </h2>

  <span class="sdps-m-horizontal_large sdps-display-inline-block client-selection-indicator"
        [ngClass]="this.externalAccess ? 'external-access' : (addlinkService.clientInformation | async)?.businessUnit | lowercase"
        [innerHTML]="(addlinkService.clientInformation | async)?.k4ClientName">
  </span>

  <ems-confirm-message #confirmationMessage></ems-confirm-message>

  <div>
    <button *ngIf="displaySetupNewUser" type="button" class="buttons button-save sdps-pull-left sdps-m-top_small sdps-m-left_small"
            [routerLink]="['create-user']" (click)="sharedService.isDTBtnClicked=true; sharedService.loadDataTrackValue('setupnewuser')">
      <em class="pi pi-plus"></em>
      {{ getUsersConstant.manageUserPage.addNewUser }}
    </button>

  <ems-table-record-count class="tbl-rec-count" [count]="totalUsers" (emitter)="pageObject.pageNo = 0;pageObject.pageSize = $event;applyFilter();"
    [selectedCount]='showSelectedcount' [storedVal] = 'stateVal && stateVal.pageObj.pageSize ? stateVal.pageObj.pageSize : 15' *ngIf="userList && !showSelection"
                          [showHeaderIcons]="true" [showTotal]='totalUsers' [showRecords]="paginationCount && paginationCount > sharedConstant.defaultTableRecords"></ems-table-record-count>

  <form [formGroup]="manageUserListForm">
  <p-table #table [columns]="userListTableCols" [value]="userList" metaKeySelection="true" [lazy]="true"
           [customSort]="true" (onLazyLoad)="customSort($event)" [multiSortMeta]="multiSortMeta" sortMode="multiple"
           class="common-data-table manage-client-list-table">
    <ng-template pTemplate="header" let-columns>
      <tr class="filter-header-input">
        <th class="table-checkbox-selection filter-icon">
          <a (click)="sharedService.gutTracking('adobe-fl_manageusersfilter|fireonce'); toggleFunction()" class="cursor">
            <em class="pi pi-filter"></em>
          </a>
        </th>
        <ng-container *ngFor="let col of columns">
        <th *ngIf="(col.header !== 'Business Unit' && col.header!=='userId' && col.header !== 'Action')" [pSortableColumnDisabled]="col.field === 'roles'" [pSortableColumn]="col.field" [attr.arialabel]="commonConstants.sorting.sortText"
            [attr.ariaLabelDesc]="commonConstants.sorting.descText" [attr.ariaLabelAsc]="commonConstants.sorting.ascText" [ngClass]="[(paginationCount && paginationCount > 1 || filterClicked) ? 'pointer-events-auto' : 'pointer-events-none']">
          <span>
            {{col.header}}
          </span>
          <span>
            <p-sortIcon [field]="col.field" *ngIf="col.field !== 'roles' && (paginationCount && paginationCount > 1 || filterClicked)"></p-sortIcon>
          </span>
        </th>
          <th *ngIf="(col.header === 'Business Unit' && this.sharedService.isInternalApp()) && col.header !== 'userId' && col.header !=='Action'"
              [pSortableColumn]="col.field" [attr.arialabel]="commonConstants.sorting.sortText"
              [attr.ariaLabelDesc]="commonConstants.sorting.descText"
              [attr.ariaLabelAsc]="commonConstants.sorting.ascText"
              [ngClass]="[(paginationCount && paginationCount > 1 || filterClicked) ? 'pointer-events-auto' : 'pointer-events-none']">
            <span>
              {{col.header}}
            </span>
            <span>
            <p-sortIcon [field]="col.field" *ngIf="(paginationCount && paginationCount > 1 || filterClicked)"></p-sortIcon>
          </span>
          </th>
          <th *ngIf="col.header === 'Action' && toggle" class="w-200-px">
            <span>
              {{col.header}}
            </span>
          </th>
        </ng-container>
        <!-- <th *ngIf="this.toggle" class="filter-action"></th> -->
      </tr>
      <tr *ngIf="this.toggle" class="filtered-row-input">
        <th></th>
        <ng-container *ngFor="let col of columns ;let i = index;">
        <th *ngIf="(col.header !== 'Business Unit' && col.header!=='userId' && col.header !== 'Action')" class="sdps-p-left_small">
          <input pInputText formControlName="firstName" restrictValidation emsNamePatternCheck class="w-100-per" type="text" name="filter" (keydown.enter)="!(filterBtnDisableForEmail || filterBtnDisableForLastName || filterBtnDisableForFirstName) ? applyFilter(): ''" onkeydown="return (event.keyCode !== 13);"
                 *ngIf="col.field === 'firstName'" [maxLength]="30" [ngClass]="{'invalidField': filterBtnDisableForFirstName || (manageUserListForm.get('firstName').value && (manageUserListForm.get('firstName').invalid))}">
          <input pInputText formControlName="lastName" restrictValidation emsNamePatternCheck class="w-100-per" type="text" name="filter" (keydown.enter)="!(filterBtnDisableForEmail || filterBtnDisableForLastName || filterBtnDisableForFirstName) ? applyFilter(): ''" onkeydown="return (event.keyCode !== 13);"
                 *ngIf="col.field === 'lastName'" [maxLength]="30" [ngClass]="{'invalidField': filterBtnDisableForLastName || (manageUserListForm.get('lastName').value && (manageUserListForm.get('lastName').invalid))}">
          <input pInputText formControlName="emailAddresses" class="w-100-per" type="text" name="filter" (keydown.enter)="!(filterBtnDisableForEmail || filterBtnDisableForLastName || filterBtnDisableForFirstName) ? applyFilter(): ''" onkeydown="return (event.keyCode !== 13);"
                 *ngIf="col.field === 'emailAddresses'" [ngClass]="{'invalidField': filterBtnDisableForEmail}">
          <!-- Integrated Primeng Date-Range Component -->
          <div *ngIf="col.enum === 'calendar'">
          <ems-date-range-filter #range (submittedDate)="getSelectedDate($event);">
          </ems-date-range-filter>
          </div>

          <div *ngIf="col.enum === 'multiselect'"
               class="col-2 roles-filter emtr-dropdown timePickerForm request-list-filter request-list-multiselect-font-size request-list-filter-multiselect">
            <p-multiSelect appendTo="body" formControlName="{{col.field}}" [options]="rolesDropdownData"
                           [filter]="true" [defaultLabel]="' '" [displaySelectedLabel]="true"
                           [maxSelectedLabels]="1" [showHeader]="true"
                           [filterPlaceHolder]="'All'" [filter]="true">
            </p-multiSelect>
          </div>
          <div class="col-2 emtr-dropdown timePickerForm request-list-filter request-list-multiselect-font-size request-list-filter-multiselect" *ngIf="col.field === 'status'">
            <p-multiSelect appendTo="body" formControlName="{{col.field}}"
                           [options]="getUsersConstant.manageUserPage.manageUserFilter[col.field]"
                           [filter]="true" [defaultLabel]="' '" [displaySelectedLabel]="true"
                           [maxSelectedLabels]="1"[showHeader]="true" [filterPlaceHolder]="'All'" [filter]="true" [showToggleAll]="false">
            </p-multiSelect>

          </div>
          <div class="timePickerForm user-type" *ngIf="(col.field === 'userType' && this.sharedService.isInternalApp())">
            <p-dropdown appendTo="body" [options]="getUsersConstant.manageUserPage.manageUserFilter[col.field]" placeholder=" " formControlName="{{col.field}}"
                        class="sdps-p-top_small editableTimepicker request-list-header-dropdown" optionLabel="label">
            </p-dropdown>
          </div>
        </th>
          <th *ngIf="(col.field === 'businessUnit' && this.sharedService.isInternalApp())">
            <div class="timePickerForm bu-filter" *ngIf="isBusinessUnit">
              <p-dropdown appendTo="body" [options]="userBUData" placeholder=" " formControlName="{{col.field}}"
                          class="sdps-p-top_small editableTimepicker request-list-header-dropdown" optionLabel="label">
              </p-dropdown>
            </div>
          </th>
          <th *ngIf="col.field === 'actions'" class="sdps-p-right_none w-200-px button-align">
            <button class="buttons button-filter" [innerHTML]="getAdlConst.requestListFilterButtons.clearAllButton"
              [disabled]="!manageUserListForm.dirty && !isSortingChanged"
              (click)="isClickedClearBtn = true;filterClicked? clickedClearButton(isSortingChanged) : resetForm(isSortingChanged); clearSuccessAndErrorMessages();;"></button>
            <button class="buttons button-save button-filter"
              [disabled]="filterBtnDisableForEmail || filterBtnDisableForLastName || filterBtnDisableForFirstName|| (manageUserListForm.get('firstName').value && (manageUserListForm.get('firstName').invalid)) || (manageUserListForm.get('lastName').value && (manageUserListForm.get('lastName').invalid))"
              (click)="applyFilter()" [innerHTML]="getAdlConst.requestListFilterButtons.applyFilterButton"></button>
          </th>
        </ng-container>
      </tr>

    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length + 1" class="sdps-text-center" [innerHTML]="getUsersConstant.manageUserPage.noRecords">
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData">
        <td class="table-checkbox-selection filter-icon"></td>
        <ng-container *ngFor="let col of columns;let i = index;">
        <td *ngIf="col.field !== 'businessUnit' && col.field !== 'actions'" class="sdps-truncate--ellipses">
          <span *ngIf="col.field === 'clientId'" class="wrapText" [innerHTML]="rowData[col.field]">
          </span>
          <span *ngIf="col.field === 'firstName'"class="userNameLinks cursor"  title="{{rowData[col.field]}}" (click)="editUser(rowData['userId'])" [innerHTML]="rowData[col.field]">
          </span>
          <span *ngIf="col.field === 'lastName'" class="userNameLinks cursor" title="{{rowData[col.field]}}" (click)="editUser(rowData['userId'])" [innerHTML]="rowData[col.field]">
          </span>
          <span *ngIf="col.field === 'roles'">
            <ul>
              <li *ngFor="let roles of rowData[col.field]" class="sdps-truncate sdps-p-bottom_xx-small" title="{{roles}}" [innerHTML]="roles "></li>
            </ul>
          </span>
          <span *ngIf="col.field === 'userType'" title="{{rowData[col.field] | titlecase }}" [innerHTML]="rowData[col.field] | titlecase">
          </span>
          <span *ngIf="col.field === 'updatedDate'" [innerHTML]="rowData[col.field] | date: 'MM/dd/yyyy'">
          </span>

          <span *ngIf="col.field === 'emailAddresses'" title="{{rowData[col.field]}}">
            <ul>
              <li *ngFor="let email of rowData.emailAddresses" title=" {{email}}" class="sdps-list--horizontal" [innerHTML]="email">
              </li>
            </ul>
          </span>
          <span *ngIf="col.field ==='status' && rowData['status']" class="sdps-p-around-xx-large">
            <span class="status" ngClass="{{rowData['status'] | lowercase}}">
              <span class="sdps-m-left_large" [innerHTML]="rowData['status'] | titlecase"></span>
            </span>
          </span>

        </td>
          <td *ngIf="col.field === 'businessUnit' && this.sharedService.isInternalApp()" class="sdps-truncate--ellipses">
            <span [innerHTML]="rowData[col.field]">
          </span>
          </td>
      </ng-container>
        <td *ngIf="this.toggle"></td>
      </tr>
    </ng-template>

  </p-table>
    </form>
  <p-paginator [alwaysShow]="paginationCount && paginationCount > sharedConstant.defaultTableRecords ? true : false" [rows]="pageObject.pageSize" [totalRecords]="paginationCount" (onPageChange)="paginate($event);" class="common-pagination"
     #p></p-paginator>

  </div>
