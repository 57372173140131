<ems-service-error *ngIf="sharedService.errorMessage | async"></ems-service-error>
<sdps-notification type="error" class="sdps-m-top_none"
  *ngIf="(createNewClientForm.get('clientName').value && createNewClientForm.get('clientName').invalid) ||
          (createNewClientForm.get('registrationNameOther').value && createNewClientForm.get('registrationNameOther').invalid) ||
          (createNewClientForm.get('commissionCode').value && createNewClientForm.get('commissionCode').invalid) ||
          (createNewClientForm.get('index').value && createNewClientForm.get('index').invalid) ||
          (createNewClientForm.get('authAgentFirstNm').value && createNewClientForm.get('authAgentFirstNm').invalid) ||
          (createNewClientForm.get('authAgentLastNm').value && createNewClientForm.get('authAgentLastNm').invalid) ||
          (createNewClientForm.get('custId').value && createNewClientForm.get('custId').invalid)||
          (createNewClientForm.get('k4Master').value && createNewClientForm.get('k4Master').invalid)||
          multipleFAError || emptyDropDownValue || selected10b5Option > 1 ||
          custIDExists || custIDLimit || duplicateAgentError || errorEmptyCustID || saveBtnDisabled || k4MasterExists || errorEmptyK4MasterMsg || duplicateClientErrorMsg || emtrConversionError ||
          createNewClientForm.get('clientMasterAccounts').invalid">
  <div class="sdps-notification__text"
       *ngIf="sharedService.checkEntitlements(entitlementConst.navUUID.manageClient.uuid, '', '')">
    <p>Please correct following error.</p>
    <ul class="sdps-navigation--horizontal__list error-list">
      <li
        *ngIf="(createNewClientForm.get('clientName').value && createNewClientForm.get('clientName').invalid) || (createNewClientForm.get('registrationNameOther').value && createNewClientForm.get('registrationNameOther').invalid)"
        [innerHTML]="shellConstant.manageClients.clientDetailsPage.errorMsg.invalidCharacterForName">
      </li>
      <li *ngIf="(createNewClientForm.get('clientMasterAccounts').invalid)"
          [innerHTML]="getConstant.rulesConstant.ruleNameMsg.invalidCharacter">
      </li>
      <li *ngIf="duplicateAgentError"
          [innerHTML]="shellConstant.manageClients.clientDetailsPage.authorizedAgentNameAlreadyExists">
      </li>
      <li
        *ngIf="!manageClientService.editMode && (errorEmptyCustID || (createNewClientForm.get('custId').value && createNewClientForm.get('custId').invalid))"
        [innerHTML]="shellConstant.manageClients.clientDetailsPage.errorMsg.custIDIsInvalid">
      </li>
      <li
        *ngIf="manageClientService.editMode && (errorEmptyCustID || (createNewClientForm.get('custId').value && createNewClientForm.get('custId').invalid))"
        [innerHTML]="shellConstant.manageClients.clientDetailsPage.errorMsg.custIDIsInvalidForEditMode">
      </li>
      <li *ngIf="errorEmptyK4MasterMsg || (createNewClientForm.get('k4Master').value && createNewClientForm.get('k4Master').invalid)"
          [innerHTML]="shellConstant.manageClients.clientDetailsPage.errorMsg.masterAccountInvalidChar">
      </li>
      <li
        *ngIf="saveBtnDisabled && (!manageClientService.editMode && !(errorEmptyCustID || (createNewClientForm.get('custId').value && createNewClientForm.get('custId').invalid)))"
        [innerHTML]="shellConstant.manageClients.clientDetailsPage.errorMsg.changeCustId">
      </li>
      <li
        *ngIf="(createNewClientForm.get('commissionCode').value && createNewClientForm.get('commissionCode').invalid) ||
               (createNewClientForm.get('index').value && createNewClientForm.get('index').invalid)"
        [innerHTML]="shellConstant.manageClients.clientDetailsPage.errorMsg.invalidCharacterEnteredAlphaNumericValue">
      </li>
      <li
        *ngIf="(createNewClientForm.get('authAgentFirstNm').value && createNewClientForm.get('authAgentFirstNm').invalid) ||
               (createNewClientForm.get('authAgentLastNm').value && createNewClientForm.get('authAgentLastNm').invalid)"
        [innerHTML]="shellConstant.manageClients.clientDetailsPage.errorMsg.invalidCharacterEnteredAlphabeticValue">
      </li>
      <li *ngIf="custIDExists"
          [innerHTML]="shellConstant.manageClients.clientDetailsPage.errorMsg.custIDAlreadyExist">
      </li>
      <li *ngIf="custIDLimit"
          [innerHTML]="shellConstant.manageClients.clientDetailsPage.errorMsg.custIDLimit">
      </li>
      <li *ngIf="k4MasterExists"
          [innerHTML]="shellConstant.manageClients.clientDetailsPage.errorMsg.masterAccountAlreadyExist">
      </li>
      <li *ngIf="multipleFAError"
          [innerHTML]="shellConstant.manageClients.clientDetailsPage.errorMsg.moreThanOneFASelected">
      </li>
      <li *ngIf="emptyDropDownValue"
          [innerHTML]="shellConstant.manageClients.clientDetailsPage.errorMsg.noFAorSLMasterAccountSelected">
      </li>
      <li *ngIf="selected10b5Option > 1"
          [innerHTML]="shellConstant.manageClients.clientDetailsPage.errorMsg.moreThanOne10b5Selected">
      </li>
      <li *ngIf="duplicateClientErrorMsg"
          [innerHtml]="duplicateClientErrorMsgTxt">
      </li>
      <li *ngIf="emtrConversionError"
      [innerHtml]="shellConstant.manageClients.clientDetailsPage.errorMsg.emtrConversionCannotBeDone">
  </li>
    </ul>
  </div>
</sdps-notification>
<!-- Reactive / Deactive -->
<ems-confirm-message #confirmationMessage [businessJustification]="businessJustification"   (deactivationReason)="clickProceedToDeactivateOrReactivate($event)"></ems-confirm-message>

<h2 class="page-title sdps-m-bottom_large" *ngIf="!manageClientService.editMode"
    [innerHTML]="shellConstant.manageClients.clientDetailsPage.setupClientPage"></h2>
<h2 class="page-title sdps-m-bottom_large" *ngIf="manageClientService.editMode"
    [innerHTML]="shellConstant.manageClients.clientDetailsPage.editClientPage"></h2>

    <div class="select-rule-type" [ngClass]="!manageClientService.editMode || showDeactivationBtn ? 'step-border-box': ''">
      <div class="sdps-grid-container sdps-wrap no-margin">
        <div class="sdps-row sdps-m-top_small">
          <div class="select-rule-type"
            [ngClass]="manageClientService.editMode && !showDeactivationBtn ? 'step-border-box col-8': 'col'">
  <form [formGroup]="createNewClientForm" [ngClass]="{'pointer-events-none':  manageClientService.editMode && !(showDeactivationBtn)}">

    <!-- Business Unit, K4Master and CustId Section -->
    <div class="sdps-grid-container no-margin">
      <!--  BUSINESS UNIT -->
      <div class="col-4 sdps-p-top_small timePickerForm">
        <p>
          <span [innerHTML]="shellConstant.manageClients.clientDetailsPage.businessUnit"></span>
          <span class="sdps-text-market-red" [innerHTML]="commonConstant.asterix"></span>
        </p>

        <div class="sdps-grid-fluid sdps-p-left_x-small">
          <p-dropdown [options]="businessUnitData" placeholder="Select" 
                      class="editableTimepicker business-unit-dropdown" optionLabel="name" 
                      formControlName="businessUnit">
          </p-dropdown>
        </div>
      </div>

      <!--  K4MASTER -->
      <div class="col-4 sdps-p-top_small" *ngIf="selectedBU === shellConstant.rbsBUText">
        <p>
          <span [innerHTML]="shellConstant.workBenchChart.addLink.masterSLAcc.masterSLAccTxt"></span>
          <span class="sdps-text-market-red" [innerHTML]="commonConstant.asterix"></span>
        </p>
        <div class="sdps-grid-fluid sdps-p-left_x-small">
          <div class="col-5 rule-type-textbox sdps-p-around_x-small"
               [ngClass]="{'invalidField': ((createNewClientForm.get('k4Master').value && createNewClientForm.get('k4Master').invalid) || errorEmptyK4Master || k4MasterExists),'input-disabled': manageClientService.editMode}">
              <span class="p-float-label">
                <input type="text" name="custId" [maxlength]="8" onkeypress="if(event.which<48 || event.which>57) return false;" formControlName="k4Master" emsNumberPatterncheck
                       (keyup.enter)="searchMasterAccounts('')" pInputText/>
              </span>
          </div>
          <div class="col-2" *ngIf="!manageClientService.editMode">
            <button type="button" class="sch-search buttons button-search"
                    [disabled]="disableK4MasterSearchBtn || (createNewClientForm.get('k4Master').value && createNewClientForm.get('k4Master').invalid)"
                    (click)="searchMasterAccounts('')"></button>
          </div>
        </div>
      </div>

      <!--  CUST-ID -->
      <div class="col-4 sdps-p-top_small">
        <p class="sdps-p-left_none">
          <span [innerHTML]="shellConstant.manageClients.clientDetailsPage.custId"></span>
          <span class="sdps-text-market-red" *ngIf="selectedBU !== shellConstant.rbsBUText"
                [innerHTML]="commonConstant.asterix"></span>
        </p>
        <div class="sdps-grid-fluid sdps-p-left_x-small">
          <div class="col-5 rule-type-textbox sdps-p-around_x-small"
               [ngClass]="{'invalidField': ((createNewClientForm.get('custId').value && createNewClientForm.get('custId').invalid) || errorEmptyCustID || custIDExists),'input-disabled': (manageClientService.editMode || selectedBU === shellConstant.rbsBUText)}">
              <span class="p-float-label">
                <input type="text" name="custId" [maxlength]="10" onkeypress="if(event.which<48 || event.which>57) return false;" formControlName="custId" emsNumberPatterncheck
                       (keyup.enter)="searchMasterAccounts('')" pInputText/>
              </span>
          </div>
          <div class="col-2" *ngIf="!manageClientService.editMode && selectedBU !== shellConstant.rbsBUText">
            <button type="button" class="sch-search buttons button-search" [disabled]="searchBtnDisabled"
                    (click)="searchMasterAccounts('')"></button>
          </div>
        </div>
      </div>
    </div>

    <!-- Registration Name and Client Name Section -->
    <div class="sdps-grid-container no-margin">
      <!--  REGISTRATION NAME -->
      <div class="col-6 timePickerForm" *ngIf="selectedBU === shellConstant.rbsBUText">
        <p>
          <span [innerHTML]="shellConstant.manageClients.clientDetailsPage.registrationName"></span>
          <span class="sdps-text-market-red" [innerHTML]="commonConstant.asterix"></span>
        </p>

        <div class="sdps-grid-fluid sdps-p-left_x-small">
          <p-dropdown [options]="registrationNameData" placeholder="" optionLabel="label"
                      class="editableTimepicker business-unit-dropdown sdps-m-bottom_medium"
                      formControlName="registrationName">
          </p-dropdown>

          <!--  ADD CUSTOM REGISTRATION NAME -->
          <div class="col-4 custom-registration-name"
               *ngIf="createNewClientForm.get('registrationName').value && createNewClientForm.get('registrationName').value.value === shellConstant.manageClients.clientDetailsPage.customName">
            <div class="ems-input"
                 [ngClass]="{'invalidField': ((createNewClientForm.get('registrationNameOther').value && createNewClientForm.get('registrationNameOther').invalid) || duplicateClientErrorMsg)}">
              <span class="p-float-label sdps-p-around_xx-small">
                <input type="text" name="registrationNameOther" formControlName="registrationNameOther" [maxLength]="30"
                       emsClientNamePatterncheck emsCharacterLimit [charMaxLimit]="30"
                       (emsCharacterLimitCheck)="showCharLimitForRegName = $event" pInputText/>
                <label class="ems-textarea-label">{{shellConstant.manageClients.clientDetailsPage.enterCustomName}}
                  <span class="highlight-color" [innerHTML]="commonConstant.asterix"></span>
                </label>
              </span>
            </div>
            <p class="col-6 sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small"
               *ngIf="createNewClientForm.get('registrationNameOther').value && createNewClientForm.get('registrationNameOther').value.trim().length > showCharLimitForRegName">{{getConstant.rulesConstant.characterLimit}}
              <span class="character-limit">{{createNewClientForm.get('registrationNameOther').value.trim().length}}/30</span>
            </p>
          </div>
        </div>
      </div>

      <!--  CLIENT NAME -->
      <div class="col-4 sdps-p-around_x-small" *ngIf="selectedBU !== shellConstant.rbsBUText">
        <div class="sdps-grid-fluid">
          <div class="col-6 sdps-p-right_none">
            <div class="rule-type-textbox sdps-p-around_medium"
                 [ngClass]="{'invalidField': ((createNewClientForm.get('clientName').value && createNewClientForm.get('clientName').invalid)) || duplicateClientErrorMsg}">
              <span class="p-float-label">
                <input type="text" name="clientName" formControlName="clientName" emsClientNamePatterncheck emsCharacterLimit
                [charMaxLimit]="commonConstant.charLimitValues.twoHundredMaxLimit" [maxlength]="commonConstant.charLimitValues.twoHundredMaxLimit"
                (emsCharacterLimitCheck)="showClientNameCharLimit = $event" pInputText/>
                <label>
                  {{shellConstant.manageClients.clientDetailsPage.clientName}}
                  <span class="sdps-text-market-red" [innerHTML]="commonConstant.asterix"></span>
                </label>
              </span>
            </div>
            <p class="col-3 sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small"
              *ngIf="createNewClientForm.get('clientName').value && createNewClientForm.get('clientName').value.length > showClientNameCharLimit">
              {{getConstant.rulesConstant.characterLimit}}
              <span class="character-limit">{{createNewClientForm.get('clientName').value.length}}
                /{{commonConstant.charLimitValues.twoHundredMaxLimit}}</span>
            </p>
          </div>
        </div>

        <div class="sdps-grid-fluid"
             *ngIf="createNewClientForm.get('clientName').value && createNewClientForm.get('clientName').value.length > showCharLimit">
          <p class="col-6 sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small">
            {{getConstant.rulesConstant.characterLimit}}
            <span class="character-limit">{{createNewClientForm.get('clientName').value.length}}
              /{{commonConstant.charLimitValues.thirtyFiveMaxLimit}}</span>
          </p>
        </div>
      </div>
    </div>

    <!-- Master Account(s) Section for DBS and SPS BU-->
    <div class="sdps-grid-container no-margin" *ngIf="selectedBU !== shellConstant.rbsBUText">
      <div class="col" *ngIf="searchButtonClicked">
        <div class="sdps-grid-fluid sdps-wrap" formGroupName="clientMasterAccounts">
          <div class="col-8">
            <label>
              <span [innerHTML]="shellConstant.manageClients.clientDetailsPage.masterAccounts"></span>
              <span class="sdps-text-market-red" [innerHTML]="commonConstant.asterix"></span>
            </label>
          </div>
          <div class="col-6" *ngFor="let slAccnt of masterAccountsValue">
            <div class="sdps-m-top_xx-small" [formGroupName]="slAccnt">
              <div class="display-flex align-items-flex-start sdps-p-bottom_medium timePickerForm">
                <p-checkbox class="emtr-checkbox sdps-p-right_medium align-accounts" binary="true"
                            [label]="slAccnt" formControlName="masterAccountNbr" [trueValue]="slAccnt"
                            (onChange)="checkMasterAccountSelected()"></p-checkbox>
                <p-dropdown [options]="masterAccountDropDownData" placeholder=" " formControlName="accountType"
                            (onChange)="checkMasterAccountSelected()"
                            [ngClass]="{'time-errorHighlight': ((createNewClientForm.get('clientMasterAccounts').get(slAccnt).get('accountType').value &&createNewClientForm.get('clientMasterAccounts').get(slAccnt).get('accountType').value === 'FA' && multipleFAError) || (createNewClientForm.get('clientMasterAccounts').get(slAccnt).get('masterAccountNbr').value && !createNewClientForm.get('clientMasterAccounts').get(slAccnt).get('accountType').value && emptyDropDownValue) || (createNewClientForm.get('clientMasterAccounts').get(slAccnt).get('accountType').value && selected10b5Option > 1 && createNewClientForm.get('clientMasterAccounts').get(slAccnt).get('accountType').value === '10b5-1'))}"
                            class="editableTimepicker master-account-dropdown sdps-p-right_medium">
                </p-dropdown>
                <div class="rule-type-textbox sdps-p-around_small sdps-display-inline-block col-3"
                     [ngClass]="[((createNewClientForm.get('clientMasterAccounts').get(slAccnt).get('clientLabel').value && createNewClientForm.get('clientMasterAccounts').get(slAccnt).get('clientLabel').invalid)) ? 'invalidField' : '' || (((!createNewClientForm.get('clientMasterAccounts').get(slAccnt).get('masterAccountNbr').value)) ? 'input-disabled': '')]">
                  <span class="p-float-label">
                    <input type="text" name="slMasterAccount" class="business-unit-textbox"
                           formControlName="clientLabel" emsAlphaNumericSpecialCharacterValidation pInputText/>
                    <label [innerHTML]="shellConstant.manageClients.clientDetailsPage.label"></label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Master Account(s) Section for RBS BU-->
    <div class="sdps-grid-container no-margin" *ngIf="selectedBU === shellConstant.rbsBUText">
      <div class="col" *ngIf="searchButtonClicked">
        <div class="sdps-grid-fluid sdps-wrap" formGroupName="clientMasterAccounts">
          <div class="col-9">
            <label>
              <span [innerHTML]="shellConstant.manageClients.clientDetailsPage.masterAccounts"></span>
              <span class="sdps-text-market-red" [innerHTML]="commonConstant.asterix"></span>
            </label>
          </div>
          <div class="col-4" *ngFor="let k of objectKeys(masterAccountsValue)">
            <div class="sdps-m-top_xx-small" [formGroupName]="masterAccountsValue[k]">
              <div class="sdps-display-inline-block sdps-p-bottom_medium timePickerForm">
                <p-checkbox class="emtr-checkbox sdps-p-right_medium align-accounts" binary="true" [disabled]="true"
                            [label]="masterAccountsValue[k]" formControlName="masterAccountNbr" [trueValue]="masterAccountsValue[k]"></p-checkbox>
                <p-dropdown [options]="shellConstant.manageClients.clientDetailsPage.k4MasterAccountDropdown"
                            formControlName="accountType" [disabled]="true"
                            [placeholder]="shellConstant.manageClients.clientDetailsPage.k4MasterAccountDropdown[k]"
                            class="editableTimepicker master-account-dropdown sdps-p-right_medium">
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Applications and Access Type Section -->
    <div class="sdps-grid-container no-margin">
      <div class="col">
        <div class="sdps-grid-fluid">
          <!--  APPLICATIONS -->
          <div class="col-6" *ngIf="selectedBU !== shellConstant.rbsBUText">
            <p>
              <span [innerHTML]="shellConstant.manageClients.clientDetailsPage.applications"></span>
              <span class="sdps-text-market-red" [innerHTML]="commonConstant.asterix"></span>
            </p>

            <div class="sdps-m-around_none sdps-p-top_small">
              <div class="sdps-p-right_xx-large sdps-display-inline-block">
                <p-checkbox class="emtr-checkbox" binary="true" formControlName="addlink"
                            [label]="shellConstant.manageClients.clientDetailsPage.newClientLabels.addlink"
                            (click)="!accountTypeSL?updateApplications(): ''">
                </p-checkbox>
              </div>
              <div class="sdps-p-right_xx-large sdps-display-inline-block">
                <p-checkbox class="emtr-checkbox" binary="true" formControlName="emtr"
                            [label]="shellConstant.manageClients.clientDetailsPage.newClientLabels.emtr"
                            (click)="!accountTypeSL?[updateApplications(),disableCheckbox()]:null">

                </p-checkbox>
                <label *ngIf="manageClientService.editMode && emtrConversionStatus !== null && emtrConversionStatus !== 'COMPLETED'">
                  <span class="conversion-status-padding sdps-p-left_x-small" [innerHTML]="shellConstant.manageClients.clientDetailsPage.emtrConversionStatus"></span>
                </label>
              </div>
            </div>
          </div>

          <!--  ACCESS TYPE -->
          <div class="col-4">
            <p>
              <span [innerHTML]="shellConstant.manageClients.clientDetailsPage.accessType"></span>
            </p>
            <div class="sdps-m-around_none sdps-p-top_small">
              <div class="sdps-p-right_xx-large sdps-display-inline-block">
                <p-checkbox class="emtr-checkbox" binary="true"
                            [label]="shellConstant.manageClients.clientDetailsPage.external"
                            formControlName="externalAccess">
                </p-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Authorized Agent Section -->
    <div class="sdps-grid-container no-margin"
         *ngIf="(selectedBU === 'DBS')">
      <div class="col sdps-m-top_medium">
        <div class="sdps-grid-fluid">
          <div class="col-8">
            <div class="sdps-row">
              <p>
                <span [innerHTML]="shellConstant.manageClients.clientDetailsPage.authorizedAgent"></span>
              </p>
            </div>
          </div>
        </div>
        <div class="sdps-grid-fluid">
          <div class="col-12">
            <div class="sdps-row">
              <div class="col-4 sdps-p-around_none">
                <div class="rule-type-textbox sdps-p-around_medium"
                     [ngClass]="{'invalidField': ((createNewClientForm.get('authAgentFirstNm').value && createNewClientForm.get('authAgentFirstNm').invalid) || duplicateAgentError)}">
                  <span class="p-float-label">
                    <input type="text" name="authAgentFirstNm" [maxlength]="100" formControlName="authAgentFirstNm"
                           restrictValidation emsNamePatternCheck (ngModelChange)="duplicateAgentError=false"
                           pInputText/>
                    <label>
                      {{shellConstant.manageClients.clientDetailsPage.firstName}}
                    </label>
                  </span>
                </div>
              </div>

              <div class="col-4 sdps-p-around_none sdps-m-left_large">
                <div class="rule-type-textbox sdps-p-around_medium"
                     [ngClass]="{'invalidField': ((createNewClientForm.get('authAgentLastNm').value && createNewClientForm.get('authAgentLastNm').invalid) || duplicateAgentError)}">
                  <span class="p-float-label">
                    <input type="text" name="authAgentLastNm" [maxlength]="100" formControlName="authAgentLastNm"
                           restrictValidation emsNamePatternCheck (ngModelChange)="duplicateAgentError=false"
                           pInputText/>
                    <label [innerHTML]="shellConstant.manageClients.clientDetailsPage.lastName"></label>
                  </span>
                </div>
              </div>

              <div class="col-4">
                <button type="button" class="buttons add-agent"
                        routerLinkActive="active" [innerHTML]="shellConstant.manageClients.clientDetailsPage.addAgent"
                        (click)="addAgent()"
                        [disabled]="createNewClientForm.get('authAgentFirstNm').invalid || createNewClientForm.get('authAgentLastNm').invalid || !createNewClientForm.value.authAgentFirstNm || !createNewClientForm.value.authAgentLastNm"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="sdps-grid-fluid" *ngIf="authAgents.length">
          <div class="col-5">
            <p-table class="common-data-table authorized-agent-table sdps-p-around_none"
                     [value]="authAgents" [scrollable]="true" scrollHeight="150px">
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th [innerHTML]="shellConstant.manageClients.clientDetailsPage.firstName"></th>
                  <th [innerHTML]="shellConstant.manageClients.clientDetailsPage.lastName"></th>
                  <th class="w-10-per"></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-authAgents let-rowIndex="rowIndex">
                <tr [ngClass]="{'disabled-grey': authAgents.authAgentFirstNm === shellConstant.manageClients.clientDetailsPage.exception && authAgents.authAgentLastNm === shellConstant.manageClients.clientDetailsPage.dbs}">
                  <td>{{authAgents.authAgentFirstNm}}</td>
                  <td>{{authAgents.authAgentLastNm}}</td>
                  <td [ngClass]="{'hide-icon': authAgents.authAgentFirstNm === shellConstant.manageClients.clientDetailsPage.exception && authAgents.authAgentLastNm === shellConstant.manageClients.clientDetailsPage.dbs}" class="w-10-per character-limit"><em class="pi pi-times" (click)="removeAgent(rowIndex)"></em>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>

    <!-- Link Type and Specialized Linking Section -->
    <div class="sdps-grid-container no-margin" *ngIf="selectedBU !== shellConstant.rbsBUText">
      <div class="col sdps-m-top_small">
        <div class="sdps-grid-fluid">
          <!--  LINK TYPE -->
          <div class="col-6">
            <p>
              <span [innerHTML]="shellConstant.manageClients.clientDetailsPage.linkType"></span>
              <span class="sdps-text-market-red" [innerHTML]="commonConstant.asterix"></span>
            </p>

            <div class="emtr-radio sdps-grid-container sdps-m-around_none sdps-p-top_small">
              <p-radioButton class="col-4 sdps-p-left_none" name="linkType" value="Standard" label="Standard"
                             [disabled]="disableLinkTypeFor10b5"
                             formControlName="linkType"></p-radioButton>

              <p-radioButton class="col-4" name="linkType" value="Dual" label="Dual" [disabled]="disableLinkTypeFor10b5"
                             formControlName="linkType"></p-radioButton>
            </div>
          </div>

          <!--  CLIENT TYPE -->
          <div class="col-3" *ngIf="selectedBU === 'DBS'">
            <p>
              <span [innerHTML]="shellConstant.manageClients.clientDetailsPage.clientType"></span>
            </p>
            <div class="sdps-m-around_none sdps-p-top_small">
              <div class="sdps-p-right_xx-large sdps-display-inline-block">
                <p-checkbox class="emtr-checkbox" binary="true"
                            [label]="shellConstant.manageClients.clientDetailsPage.charitableClient"
                            formControlName="charitable" (onChange)="charitableClientChange()">

                </p-checkbox>
              </div>
            </div>
          </div>

          <!--  SPECIALIZED LINKING -->
          <div class="col-3">
            <p>
              <span [innerHTML]="shellConstant.manageClients.clientDetailsPage.specializedLinking"></span>
            </p>
            <div class="sdps-m-around_none sdps-p-top_small">
              <div class="sdps-p-right_xx-large sdps-display-inline-block">
                <p-checkbox class="emtr-checkbox" binary="true"
                            [label]="shellConstant.manageClients.clientDetailsPage.charitableAccounts"
                            formControlName="allowCharitableAccounts">
                </p-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- comm Code, 407 Liable, Blanket 407 and Index Key Section -->
    <div class="sdps-grid-container no-margin" *ngIf="selectedBU !== shellConstant.rbsBUText">
      <div class="col">
        <div class="sdps-grid-fluid sdps-p-top_medium">
          <!--  COMMISSION CODE -->
          <div class="col-2 sdps-p-around_none">
            <div class="rule-type-textbox sdps-p-around_medium"
                 [ngClass]="{'invalidField': ((createNewClientForm.get('commissionCode').value && createNewClientForm.get('commissionCode').invalid))}">
              <span class="p-float-label">
                <input type="text" name="commissionCode" [maxlength]="100" formControlName="commissionCode"
                       class="business-unit-textbox" emsAlphaNumericPatterncheck pInputText/>
                <label [innerHTML]="shellConstant.manageClients.clientDetailsPage.commCode"></label>
              </span>
            </div>
          </div>

          <!--  407 LIABLE -->
            
          <div class="sdps-p-left_x-large sdps-p-right_medium sdps-p-top_medium col-2" *ngIf="disable407Liable &&  selectedBU === this.shellConstant.manageClients.clientDetailsPage.dbs">
            <p-checkbox class="emtr-checkbox" binary="true"
                        [label]="shellConstant.manageClients.clientDetailsPage.liable"
                        formControlName="liable407">
            </p-checkbox>
          </div>

          <div class="col-6">
            <div class="sdps-grid-fluid">
              <!--  BLANKET 407 -->
              <div class="sdps-p-top_medium sdps-p-left_x-small" [ngClass]="manageClientService.editMode && !showDeactivationBtn ? 'col-4' : 'col-3'"
                   *ngIf="createNewClientForm.get('liable407').value === true">
                <p-checkbox class="emtr-checkbox" binary="true"
                            [label]="shellConstant.manageClients.clientDetailsPage.blanketLetterOnFile"
                            formControlName="blanket407">
                </p-checkbox>
              </div>

              <!--  INDEX KEY -->
              <div class="col-3 sdps-p-around_none no-margin">
                <div class="rule-type-textbox sdps-p-around_medium"
                     *ngIf="createNewClientForm.get('liable407').value === true && createNewClientForm.get('blanket407').value === true"
                     [ngClass]="{'invalidField': ((createNewClientForm.get('index').value && createNewClientForm.get('index').invalid))}">
                <span class="p-float-label">
                  <input type="text" name="index" formControlName="index"
                         emsAlphaNumericPatterncheck pInputText/>
                  <label>{{shellConstant.manageClients.clientDetailsPage.indexKey}}
                    <span class="sdps-text-market-red" [innerHTML]="commonConstant.asterix"></span>
                  </label>
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<!-- Right side section - Status, Created by, created date... -->
<div class="step-border-box col-4 sdps-m-left_medium" *ngIf="manageClientService.editMode && !showDeactivationBtn">
  <div class="sdps-grid-container sdps-wrap">
    <div class="sdps-row sdps-p-top_medium">
      <div class="col-6">
        <strong class="font-size" [innerHTML]="shellConstant.manageClients.clientDetailsPage.updatedBy"></strong>
      </div>
      <div class="col-6 word-break" *ngIf="inactiveClientdata" [innerHTML]="inactiveClientdata.modifiedBy | titlecase">
      </div>
    </div>
    <div class="sdps-row sdps-p-top_medium">
      <div class="col-6">
        <strong class="font-size" [innerHTML]="shellConstant.manageClients.clientDetailsPage.updatedTime"></strong>
      </div>
      <div class="col-6 word-break" *ngIf="inactiveClientdata"
        [innerHTML]="inactiveClientdata.deactivationInfo.timestamp">
      </div>
    </div>
    <div class="sdps-row sdps-p-top_medium sdps-ellipsis-reason" >
      <div class="col-6" >
        <strong class="font-size"
          [innerHTML]="shellConstant.manageClients.clientDetailsPage.InactivedeactivateReason"></strong>
      </div>
      <div  class="col-6 word-break sdps-deactivate-reason" *ngIf="inactiveClientdata"
        [innerHTML]="inactiveClientdata.deactivationInfo.reason">
      </div>
      <!-- 
      Tool tip if required
      <sdps-tooltip *ngIf="showInfoIcon" class="info-icon-button-border sdps-m-left_x-small sdps-info-focus" sdps-id="sdps-info"
      position="bottom">
        <span slot="tooltip-content" [innerHTML]="inactiveClientdata.deactivationInfo.reason"></span>
      </sdps-tooltip> -->
    </div>
  </div>
</div>
</div>
</div>
</div>

<ems-step-wizard-buttons
  [disableBtn]="(createNewClientForm.get('clientName').value && createNewClientForm.get('clientName').value.trim() === '') || (manageClientService.editMode && !showDeactivationBtn) || (createNewClientForm.get('registrationNameOther').value && createNewClientForm.get('registrationNameOther').value.trim() === '') ||
   (createNewClientForm?(manageClientService.editMode ? (errorEmptyCustID || (createNewClientForm.get('custId').value && createNewClientForm.get('custId').invalid) || manageClientService.editMode && (errorEmptyCustID || (createNewClientForm.get('custId').value && createNewClientForm.get('custId').invalid))) || (createNewClientForm.invalid || !createNewClientForm.dirty || duplicateClientErrorMsg || selected10b5Option > 1) :
   (createNewClientForm.invalid || !masterAccountFormCheck || duplicateClientErrorMsg || duplicateAgentError || saveBtnDisabled || !searchButtonClicked || selected10b5Option > 1)) : '')"
  [showCancelBtn]="true" (closeBtn)="cancelCreateClient()"
  [showSaveBtn]="(manageClientService.editMode && showReactivationBtn) ? false : true"  (save)="saveNewClient()"
  [disableDeActivate]="(createNewClientForm.get('custId').value && createNewClientForm.get('custId').invalid) 
  || (errorEmptyCustID || createNewClientForm.dirty) || errorEmptyK4MasterMsg || (createNewClientForm.get('k4Master').value 
  && createNewClientForm.get('k4Master').invalid)" 
  [disableReActivate]="(createNewClientForm.get('linkType').value && createNewClientForm.get('linkType').invalid && 
  createNewClientForm.get('custId').value && createNewClientForm.get('custId').invalid)
   || (errorEmptyCustID || createNewClientForm.dirty) || errorEmptyK4MasterMsg ||
    (createNewClientForm.get('k4Master').value 
   && createNewClientForm.get('k4Master').invalid)" 
  [showDeActivateBtn]="manageClientService.editMode && showDeactivationBtn" (deActivate)="deActivateClient()"
  [showReActivateBtn]="manageClientService.editMode && showReactivationBtn" (reActivate)="reActivateClient()"
  ></ems-step-wizard-buttons>
