import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ManageClientService} from '@core_services/admin-menu/manage-client.service';
import {emtrConstant} from '../../../../../projects/ems/emtr/src/lib/constants/emtrConstants';
import {ShellConstant} from '@shell_components/constants/shellConstants';
import {Router} from '@angular/router';
import {Observable, Observer} from 'rxjs';
import {commonConstants} from '@shell_components/constants/commonConstants';
import {HeaderService} from '@core_services/header/header.service';
import {SharedService, SharedConstant} from '@ems/shared';
import {entitlementConst} from '@shell_components/constants/entitlementConstants';
import {LocalStorageService} from '@core_services/local-storage.service';

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.scss']
})
export class CreateClientComponent implements OnInit, OnDestroy {

  createNewClientForm: UntypedFormGroup;

  orgId = 0;
  sharedConstant = SharedConstant;
  getConstant = emtrConstant;
  shellConstant = ShellConstant;
  commonConstant = commonConstants;
  entitlementConst = entitlementConst;
  businessUnitData = [];
  confirmMessage: any;
  authAgents: any = [];
  masterAccountsValue: any = [];
  searchButtonClicked = false;
  duplicateAgentError = false;
  targetElem: any;
  errorEmptyCustID = false;
  custIDExists = false;
  custIDLimit = false;
  cancelBtnClicked = true;
  setUpNewClientSuccessMsg: any;
  clientMasterAccounts = this.fb.group({});
  masterAccountDropDownData: any;
  masterAccountFormCheck = false;
  multipleFAError = false;
  isMasterAccountChecked = false;
  emptyDropDownValue = false;
  businessUnit: any;
  showCharLimit: boolean;
  showCharLimitForRegName: boolean;
  searchBtnDisabled = false;
  disableK4MasterSearchBtn = false;
  saveBtnDisabled = false;
  custIdChangeMsg = false;
  accountTypeSL = false;
  selected10b5Option = 0;
  selectedEmtrOption = 0;
  selectedAddlinkOption = 0;
  clientId: any;
  addlink = false;
  emtr = false;
  disableLinkTypeFor10b5 = false;
  disable407Liable = false;
  selectedBU: any;
  previouslySelectedBU: any;
  k4MasterExists = false;
  errorEmptyK4Master = false;
  duplicateClientErrorMsg = false;
  duplicateClientErrorMsgTxt: any;
  errorEmptyK4MasterMsg: any;
  registrationNameData = [];
  emtrConversionStatus: any;
  emtrConversionError = false;
  showClientNameCharLimit: any = 0;
  businessJustification = false;
  showDeactivationBtn = false;
  showReactivationBtn = false;
  inactiveClientdata: any;
  showInfoIcon = false;

  @ViewChild('confirmationMessage', { static: true }) confirmationMessage: any;

  constructor(public fb: UntypedFormBuilder, public manageClientService: ManageClientService, private router: Router, private headerService: HeaderService, public sharedService: SharedService,
              public el: ElementRef, private localStrService: LocalStorageService) {
  }

  ngOnInit() {
    this.sharedService.disableClientSelector.next(true);
    this.createNewClientForm = this.fb.group({
      custId: ['', Validators.required],
      k4Master: ['', Validators.required],
      clientName: ['', {validators: [Validators.required]}],
      registrationName: ['', {validators: [Validators.required]}],
      registrationNameOther: [''],
      linkType: ['', Validators.required],
      addlink: [''],
      emtr: [''],
      businessUnit: ['', Validators.required],
      authAgentFirstNm: [''],
      authAgentLastNm: [''],
      commissionCode: [''],
      liable407: [''],
      blanket407: [''],
      index: [''],
      authAgents: [[], Validators.required],
      applications: ['', Validators.required],
      clientMasterAccounts: this.clientMasterAccounts,
      charitable: [false],
      allowCharitableAccounts: [false],
      externalAccess: ['']
    });
    this.manageClientService.getBusinessUnitDropdownValue().subscribe((data: any) => {
      this.businessUnitData = data.data;
    });
    this.masterAccountDropDownData = this.shellConstant.manageClients.clientDetailsPage.masterAccountDropdown;
    this.searchBtnDisabled = true;
    this.disableK4MasterSearchBtn = true;
    this.onChanges();
    this.confirmMessage = this.confirmationMessage.confirmMessage;
    this.manageClientService.clearMessage();
    if (this.manageClientService.editMode) {
      this.editClient(this.manageClientService.getCustId);
    } else {
      this.sharedService.emitClientDropdownDisableValue.next(true);
    }
  }

  charitableClientChange(intailLoad?) {
    if (this.createNewClientForm.get('charitable').value) {
      this.createNewClientForm.get('allowCharitableAccounts').setValue(true);
      this.createNewClientForm.get('allowCharitableAccounts').disable();
    } else {
      if (!intailLoad) {
        this.createNewClientForm.get('allowCharitableAccounts').setValue(false);
      }
      this.createNewClientForm.get('allowCharitableAccounts').enable();
    }
  }

  cancelCreateClient() {
    this.confirmMessage.message = this.getConstant.rulesConstant.confirmationMessage.cancelBtnClickMsg;
    this.router.navigate(['/ems/admin/manage-clients/manage-clients-list']);
    this.businessJustification = false;
  }
  deActivateClient() {
    if (this.manageClientService.editMode) {
      this.businessJustification = true;
      const confirmObj = {
        header: this.sharedConstant.confirmationDialog.deActivativateClientTitle,
        message: `${this.getConstant.rulesConstant.confirmationMessage.deActivateMsg}`,
        acceptBtn: this.sharedConstant.confirmationDialog.proceedBtn,
        rejectBtn: this.sharedConstant.deleteModal.cancelTxt,
        showInput: true
      };
      Observable.create((observer: Observer<boolean>) => {
        this.confirmationMessage.showConfirmMsg(confirmObj, observer);
      }).subscribe(accept => {
      });
    }
  }

  reActivateClient() {
    if (this.manageClientService.editMode) {
      this.businessJustification = false;
      const confirmObj = {
        header: this.sharedConstant.confirmationDialog.reActivativateClientTitle,
        message: `${this.getConstant.rulesConstant.confirmationMessage.reActivateMsg}`,
        acceptBtn: this.sharedConstant.confirmationDialog.proceedBtn,
        rejectBtn: this.sharedConstant.deleteModal.cancelTxt,
        showInput: false
      };
      Observable.create((observer: Observer<boolean>) => {
        this.confirmationMessage.showConfirmMsg(confirmObj, observer);
      }).subscribe(accept => {
      });
    }
  }
  clickProceedToDeactivateOrReactivate(reason) {
    if(reason !== ''){
    const deactivationPayload = { reason };
    if(this.businessJustification){
    this.saveNewClient(true, deactivationPayload)
    }
    } else  {
    this.saveNewClient(false, true)
    }}


  updateApplications() {
    const applications = this.createNewClientForm.getRawValue();
    this.createNewClientForm.patchValue({
      applications: (applications.addlink || applications.emtr) ? true : ''
    });
    if (applications.emtr) {
      this.disableCheckbox();
    }
  }

  disableCheckbox() {
    const applications = this.createNewClientForm.getRawValue();
    if (applications.emtr) {
      this.createNewClientForm.get('addlink').disable();
      this.createNewClientForm.patchValue({addlink: true});
    } else {
      this.createNewClientForm.patchValue({addlink: false});
      this.createNewClientForm.get('addlink').enable();
    }
  }

  addAgent() {
    const authAgentFirstNm = this.createNewClientForm.value.authAgentFirstNm.trim();
    const authFirstName = authAgentFirstNm.toUpperCase();
    const authAgentLastNm = this.createNewClientForm.value.authAgentLastNm.trim();
    const authLastName = authAgentLastNm.toUpperCase();
    const duplicatedAgent = this.authAgents.filter(a => ((a.authAgentFirstNm.toUpperCase() === authFirstName && a.authAgentLastNm.toUpperCase() === authLastName) || (a.authAgentFirstNm.toUpperCase() === authLastName && a.authAgentLastNm.toUpperCase() === authFirstName)));
    this.duplicateAgentError = false;
    if (!duplicatedAgent.length) {
      this.authAgents.push({authAgentFirstNm, authAgentLastNm});
      this.createNewClientForm.patchValue({
        authAgents: this.authAgents,
        authAgentFirstNm: '',
        authAgentLastNm: ''
      });
      if ( this.manageClientService.editMode && this.createNewClientForm.get('authAgents').value.length !== 0) {
        this.createNewClientForm.markAsDirty();
      }
    } else {
      this.duplicateAgentError = true;
    }
  }

  searchMasterAccounts(res) {
    this.errorEmptyK4MasterMsg = '';
    if(this.selectedBU !== this.shellConstant.rbsBUText){
      this.saveBtnDisabled = false;
      this.custIdChangeMsg = true;
      }
    this.emptyDropDownValue = this.multipleFAError = this.masterAccountFormCheck = false;
    this.createNewClientForm.get('clientMasterAccounts').reset();
    this.k4MasterExists = this.searchButtonClicked = this.custIDExists = false;
    let masterAcctFAK4Arr = [];
    let masterAcctNumArr = [];
    /* If the selected BU is RBS, then send K4 Master value else send Cust-Id value to the API */
    const value = this.selectedBU === this.shellConstant.rbsBUText ? this.createNewClientForm.get('k4Master').value : this.createNewClientForm.get('custId').value;
    if (value) {
      this.manageClientService.getMasterAccounts(value, this.selectedBU).subscribe((accounts) => {
        this.masterAccountsValue = this.selectedBU === this.shellConstant.rbsBUText ? accounts.data.masterAccounts : accounts.data;
        if (this.masterAccountsValue !== null) {
          /* If the selected BU is RBS, then convert the masterAccounts object into array */
          if (this.selectedBU === this.shellConstant.rbsBUText) {
            masterAcctFAK4Arr = Object.keys(this.masterAccountsValue);
            masterAcctNumArr = [];
            masterAcctFAK4Arr.forEach(prop => {
              masterAcctNumArr.push(this.masterAccountsValue[prop]);
            });
            this.createNewClientForm.get('custId').setValue(accounts.data.custId);
            const objectKeyArr = Object.keys(accounts.data.registrationName);
            const registrationNameArr = [];
            objectKeyArr.forEach(prop => {
              if (accounts.data.registrationName[prop] !== null) {
                const obj = {label: accounts.data.registrationName[prop], value: accounts.data.registrationName[prop]};
                registrationNameArr.push(obj);
              }
            });
            const newObj = {label: this.shellConstant.manageClients.clientDetailsPage.customName, value: this.shellConstant.manageClients.clientDetailsPage.customName};
            registrationNameArr.push(newObj);
            this.registrationNameData = registrationNameArr;
            this.createNewClientForm.get('registrationName').enable();
            if (!this.manageClientService.editMode) {
              this.createNewClientForm.get('registrationName').setValue(this.registrationNameData[1]);
            } else if (registrationNameArr.filter(item => (item.value === this.createNewClientForm.get('registrationName').value.value)).length === 0) {
              this.createNewClientForm.get('registrationNameOther').setValue(this.createNewClientForm.get('registrationName').value.value);
              this.createNewClientForm.get('registrationName').setValue(newObj);
            }
          }
          /* If the selected BU is RBS, then iterate through array created from object else iterate through array returned from api call */
          const accountData = this.selectedBU === this.shellConstant.rbsBUText ? masterAcctNumArr : accounts.data;
          accountData.forEach(account => {
            this.clientMasterAccounts.addControl(account, this.fb.group({
              masterAccountNbr: '',
              clientLabel: '',
              accountType: ''
            }));
          });
          this.searchButtonClicked = true;
          this.errorEmptyCustID = false;
          this.errorEmptyK4Master = false;
          /* If the selected BU is RBS, then set the api returned master account data to clientMasterAccounts form value by default */
          if (this.selectedBU === this.shellConstant.rbsBUText) {
            masterAcctFAK4Arr.forEach(account => {
              this.assignValueToClientMasterAccountForm(this.masterAccountsValue[account], this.masterAccountsValue[account], account, account, null);
            });
          }
          /* Set value to clientMasterAccounts form when edit Client is called */
          if (res && this.selectedBU !== this.shellConstant.rbsBUText) {
            this.masterAccountsValue.forEach(account => {
              res.data.clientMasterAccounts.map(data => {
                if (data.masterAccountNbr === account) {
                  this.assignValueToClientMasterAccountForm(account, data.masterAccountNbr, data.accountType, data.accountSubType, data.clientLabel);
                }
              });
            });
          }
          this.sharedService.clearErrorMessage();
          /* isMasterAccountChecked and masterAccountFormCheck is set to True if the selected BU is RBS, because for RBS BU the master account values are by default selected */
          this.isMasterAccountChecked = this.selectedBU === this.shellConstant.rbsBUText;
          this.masterAccountFormCheck = this.selectedBU === this.shellConstant.rbsBUText;
          /* checkMasterAccountSelected is the selected BU is not RBS, because for RBS BU the values are by default selected */
          if (this.selectedBU !== this.shellConstant.rbsBUText) {
            this.checkMasterAccountSelected();
          }
        } else {
          if (this.selectedBU === this.shellConstant.rbsBUText) {
            this.errorEmptyK4Master = true;
          } else {
            this.errorEmptyCustID = true;
          }
        }
      }, error => {
        const errorMsg: string = error.error.errorResponse.errorMessage;
        if (errorMsg.includes('MasterAccounts Not Found for the given custId')) {
          this.searchButtonClicked = false;
          this.custIDExists = false;
          this.errorEmptyCustID = true;
          this.sharedService.clearErrorMessage();
        } else if (errorMsg.includes('Master Account is invalid')) {
          this.errorEmptyK4MasterMsg = errorMsg;
          this.registrationNameData = [];
          this.createNewClientForm.get('custId').reset();
          this.createNewClientForm.get('registrationName').reset();
          this.createNewClientForm.get('registrationName').disable();
          this.searchButtonClicked = false;
          this.custIDExists = false;
          this.errorEmptyK4Master = true;
        }
      });
    } else {
      if (this.selectedBU === this.shellConstant.rbsBUText) {
        this.errorEmptyK4Master = true;
      } else {
        this.errorEmptyCustID = true;
      }
    }
  }

  assignValueToClientMasterAccountForm(account, masterActNum, accountType, accountSubType, clientLabel) {
    /* Set clientMasterAccounts form value - Default value for RBS and during edit Client */
    this.createNewClientForm.get('clientMasterAccounts').get([account]).get('masterAccountNbr').setValue(masterActNum);
    this.createNewClientForm.get('clientMasterAccounts').get([account]).get('accountType').setValue(accountType === 'FA' ? accountType : accountSubType);
    this.createNewClientForm.get('clientMasterAccounts').get([account]).get('clientLabel').setValue(clientLabel);
  }

  removeAgent(index) {
    const confirmObj = {
      header: this.shellConstant.manageClients.clientDetailsPage.delete,
      message: this.shellConstant.manageClients.clientDetailsPage.deleteMsgConfirmation
    };
    Observable.create((observer: Observer<boolean>) => {
      this.confirmationMessage.showConfirmMsg(confirmObj, observer);
    }).subscribe(accept => {
      if (accept) {
        this.authAgents.splice(index, 1);
        this.createNewClientForm.patchValue({
          authAgents: this.authAgents
        });
        if (this.createNewClientForm.get('authAgents').value.length !== 0) {
          this.createNewClientForm.markAsDirty();
        }
      }
    });
  }

  canDeactivate(event, observer) {
    this.confirmMessage.message = this.confirmMessage.message ? this.confirmMessage.message : this.getConstant.rulesConstant.confirmationMessage.menuClickMsg;
    if (this.createNewClientForm.dirty && this.cancelBtnClicked) {
      return this.confirmationMessage.showConfirmMsg(this.confirmMessage, observer);
    }
    return true;
  }

  saveNewClient(isDeactivation?,  deactivationPayload?) {
    this.cancelBtnClicked = false;
    /* Fetch raw data from the createNewClientForm Form */
    const localUpdatedFormValues = this.createNewClientForm.getRawValue();
    localUpdatedFormValues.blanket407 = localUpdatedFormValues.blanket407 ? 'Y' : 'N';
    localUpdatedFormValues.liable407 = localUpdatedFormValues.liable407 ? 'Y' : 'N';
    /* Set value of addlink, emtr and applications to True if the selected BU is RBS, else fetch the data from localUpdatedFormValues */
    localUpdatedFormValues.addlink = localUpdatedFormValues.addlink ? 'Y' : localUpdatedFormValues.emtr ? 'Y' : this.selectedBU === this.shellConstant.rbsBUText ? 'Y' : 'N';
    localUpdatedFormValues.emtr = localUpdatedFormValues.emtr ? 'Y' : this.selectedBU === this.shellConstant.rbsBUText ? 'Y' : 'N';
    localUpdatedFormValues.applications = this.selectedBU === this.shellConstant.rbsBUText ? true : localUpdatedFormValues.applications;
    localUpdatedFormValues.businessUnit = localUpdatedFormValues.businessUnit.name;
    /* Assign Registration name to Client name when the selected BU is RBS BU else assign Client name */
    // eslint-disable-next-line max-len
    localUpdatedFormValues.clientName = this.selectedBU === this.shellConstant.rbsBUText ? localUpdatedFormValues.registrationName.value === this.shellConstant.manageClients.clientDetailsPage.customName ? localUpdatedFormValues.registrationNameOther.trim() : localUpdatedFormValues.registrationName.value : localUpdatedFormValues.clientName.trim();
    localUpdatedFormValues.activeStatus = 'Y';
    localUpdatedFormValues.externalAccess = localUpdatedFormValues.externalAccess ? 'Y' : 'N';
    localUpdatedFormValues.allowCharitableAccounts = localUpdatedFormValues.allowCharitableAccounts ? true : this.selectedBU === this.shellConstant.rbsBUText ? null : false;
    localUpdatedFormValues.charitable = localUpdatedFormValues.charitable ? true : false;

    localUpdatedFormValues.modifiedClientMasterAccounts = [];
    Object.keys(localUpdatedFormValues.clientMasterAccounts).forEach(key => {
      localUpdatedFormValues.clientMasterAccounts[key].masterAccountNbr = localUpdatedFormValues.clientMasterAccounts[key].masterAccountNbr ? key : '';
      localUpdatedFormValues.modifiedClientMasterAccounts.push(localUpdatedFormValues.clientMasterAccounts[key]);
    });
    localUpdatedFormValues.clientMasterAccounts = localUpdatedFormValues.modifiedClientMasterAccounts.filter(val => val.masterAccountNbr);
    localUpdatedFormValues.clientMasterAccounts.map(data => data.accountType === 'FA' ? data.category = 'clientSpecificFAMaster' : '');
    if (this.selectedBU !== this.shellConstant.rbsBUText) {
      /*If accountType is other than FA assign it to accountSubtype then re-assign accountType with SL as its value*/
      localUpdatedFormValues.clientMasterAccounts.map(data => (data.accountType === 'ADDLink' || data.accountType === 'EMTR' || data.accountType === '10b5-1') ? [data.accountSubType = data.accountType, data.accountType = 'SL'] : data.accountSubType = null);
      const formValues = this.createNewClientForm.getRawValue().clientMasterAccounts;
      this.emptyDropDownValue = false;
      this.masterAccountsValue.forEach(account => {
        if (formValues[account].masterAccountNbr && !formValues[account].accountType) {
          this.emptyDropDownValue = true;
        }
      });
    } else {
      /* If accountType is FA assign accountSubtype to null and for K4/SL assign accountSubtype to EMTR */
      localUpdatedFormValues.clientMasterAccounts.map(data => (data.accountType === 'FA') ? data.accountSubType = null : data.accountSubType = 'EMTR');
    }

    delete localUpdatedFormValues.modifiedClientMasterAccounts;
    delete localUpdatedFormValues.authAgentFirstNm;
    delete localUpdatedFormValues.authAgentLastNm;
    delete localUpdatedFormValues.registrationName;
    delete localUpdatedFormValues.registrationNameOther;
    delete localUpdatedFormValues.k4Master;
    if (!localUpdatedFormValues.charitable && (this.selectedBU === this.shellConstant.rbsBUText || this.selectedBU === 'SPS')) {
      delete localUpdatedFormValues.charitable;
    }

    /*to update the local storage object with the latest externalAccess value*/
    const clientProfiles = JSON.parse(this.localStrService.getProfile());
    if (localUpdatedFormValues.externalAccess === 'Y') {
      clientProfiles.externalAccess = 'Y';
    } else {
      clientProfiles.externalAccess = 'N';
    }

    localUpdatedFormValues.charitable ? clientProfiles.charitable = true : clientProfiles.charitable = false;
    localUpdatedFormValues.linkType === 'Dual' ? clientProfiles.linkType = 'Dual' : clientProfiles.linkType = 'Standard';

    /*To disable the Save button when checkbox is checked and dropdown value is not selected*/
    this.localStrService.setProfile(JSON.stringify(clientProfiles));
    this.masterAccountFormCheck = !this.emptyDropDownValue;
    // eslint-disable-next-line max-len
    const clientName = this.selectedBU === this.shellConstant.rbsBUText ? this.createNewClientForm.get('registrationName').value.value === this.shellConstant.manageClients.clientDetailsPage.customName ? this.createNewClientForm.get('registrationNameOther').value : this.createNewClientForm.get('registrationName').value.value : this.createNewClientForm.get('clientName').value;
    const k4clientName = this.selectedBU === this.shellConstant.rbsBUText ? this.createNewClientForm.get('k4Master').value + '-' + clientName : clientName;
    if (this.masterAccountFormCheck && !this.manageClientService.editMode) {
      this.setUpNewClientSuccessMsg = '<strong>' + k4clientName + '</strong>' + this.shellConstant.manageClients.mangeClientList.setupWasSuccessfullySaved;
      this.manageClientService.saveClient([localUpdatedFormValues]).subscribe(() => {
          this.headerService.emitClient.emit();
          this.manageClientService.sendMessage(this.setUpNewClientSuccessMsg);
          this.router.navigate(['/ems/admin/manage-clients/manage-clients-list']);
        },
        error => {
          const errorMsg: string = error.error.errorResponse.errorMessage.toLowerCase();
          if (errorMsg.match('custid :')) {
            this.custIDExists = true;
            this.searchButtonClicked = this.errorEmptyCustID = false;
          } else if (errorMsg.match('cust id with maximum of 10 digits is allowed :')){
            this.custIDLimit = true;
          } else if (errorMsg.match('master account :')) {
            this.k4MasterExists = true;
            this.registrationNameData = [];
            this.searchButtonClicked = this.errorEmptyK4Master = false;
            this.createNewClientForm.get('custId').reset();
            this.createNewClientForm.get('registrationName').reset();
            this.createNewClientForm.get('registrationName').disable();
          } else if (errorMsg.match('cust id is invalid')) {
            this.errorEmptyCustID = true;
            this.searchButtonClicked = false;
          } else if (errorMsg.match('master account is invalid')) {
            this.errorEmptyK4Master = true;
            this.searchButtonClicked = false;
          } else if (errorMsg.match('please enter a different client name')) {
            this.duplicateClientErrorMsg = true;
            this.duplicateClientErrorMsgTxt = errorMsg.split(',').pop();
          }
          this.sharedService.clearErrorMessage();
          this.manageClientService.clearMessage();
        });
    } else if (!this.emptyDropDownValue) {
      this.setUpNewClientSuccessMsg = '<strong>' + k4clientName + '</strong>' + this.shellConstant.manageClients.mangeClientList.setupWasSuccessfullyUpdated;
      localUpdatedFormValues.clientId = this.clientId;
      localUpdatedFormValues.orgId = this.orgId;
      if (isDeactivation) {
        localUpdatedFormValues['deactivationInfo'] = deactivationPayload;
      } else if(isDeactivation || deactivationPayload === true){
        localUpdatedFormValues.reactivate= true;
      }
      
      this.manageClientService.updateClient(localUpdatedFormValues).subscribe(() => {
          this.headerService.emitClient.emit();
          // update the local storage with updated client
          if (clientProfiles.orgId) {
            clientProfiles.k4ClientName = k4clientName;
            clientProfiles.clientName = clientName;
            this.localStrService.setProfile(JSON.stringify(clientProfiles));
          }
          const deActivateClientSuccessMsg = '<strong>' + clientName + '</strong>' + this.shellConstant.manageClients.mangeClientList.deActivateClientSuccessMsg;
          const reActivateClientSuccessMsg = '<strong>' + clientName + '</strong>' + this.shellConstant.manageClients.mangeClientList.reActivateClientSuccessMsg;
          const successMsg = isDeactivation ? deActivateClientSuccessMsg : reActivateClientSuccessMsg;
          this.manageClientService.sendMessage(successMsg);
          this.router.navigate(['/ems/admin/manage-clients/manage-clients-list']);
          this.sharedService.emitClientDropdownDisableValue.next(true);
        },
        error => {
          const errorMsg: string = error.error.errorResponse.errorMessage;
          if (errorMsg.match('Please enter a different Client Name')) {
            this.duplicateClientErrorMsg = true;
            this.duplicateClientErrorMsgTxt = errorMsg.split(',').pop();
          } else if (errorMsg.match('Client cannot be converted back to EMTR again.')) {
            this.emtrConversionError = true;
          }
          this.sharedService.clearErrorMessage();
        });
    }
  }

  checkMasterAccountSelected() {
    let dropdownSLValues = '';
    this.isMasterAccountChecked = false;
    let formValues = this.createNewClientForm.getRawValue().clientMasterAccounts;
    if (this.masterAccountsValue !== '') {
      this.masterAccountsValue.forEach(account => {
        const masterAcntDropdown = this.masterAccountsValue.map(accountNumber => ({
          accountType: formValues[accountNumber].accountType,
          masterActNbr: formValues[accountNumber].masterAccountNbr
        }));
        this.selected10b5Option = masterAcntDropdown.filter((val) => val.masterActNbr && val.accountType === '10b5-1').length;
        this.selectedAddlinkOption = masterAcntDropdown.filter((val) => val.masterActNbr && val.accountType === 'ADDLink').length;
        this.selectedEmtrOption = masterAcntDropdown.filter((val) => val.masterActNbr && val.accountType === 'EMTR').length;
        if (this.selected10b5Option >= 1) {
          dropdownSLValues = '10b5-1';
        } else if (this.selectedEmtrOption > 0) {
          dropdownSLValues = 'EMTR';
        } else if (this.selectedAddlinkOption > 0) {
          dropdownSLValues = 'ADDLink';
        }
        this.chedkAddlinkMasterAccounts(dropdownSLValues);
        if (formValues[account].masterAccountNbr) {
          this.isMasterAccountChecked = true;
          this.createNewClientForm.get('clientMasterAccounts').get([account]).get('clientLabel').enable();
          this.createNewClientForm.get('clientMasterAccounts').get([account]).get('accountType').enable();
        } else {
          this.createNewClientForm.get('clientMasterAccounts').get([account]).get('accountType').reset();
          this.createNewClientForm.get('clientMasterAccounts').get([account]).get('accountType').disable();
          this.createNewClientForm.get('clientMasterAccounts').get([account]).get('clientLabel').reset();
          this.createNewClientForm.get('clientMasterAccounts').get([account]).get('clientLabel').disable();
        }
        if (formValues[account].masterAccountNbr && formValues[account].accountType !== '') {
          this.emptyDropDownValue = false;
        }
      });

      formValues = this.createNewClientForm.getRawValue().clientMasterAccounts;
      const dropdownvalues = this.masterAccountsValue.map(account => formValues[account].accountType);
      this.multipleFAError = dropdownvalues.filter((val, index) => dropdownvalues.indexOf(val) !== index && val === 'FA').length;

      /*To disable the Save button when checkbox is not checked or checkbox is checked and dropdown value contains multiple FA's selected*/
      this.masterAccountFormCheck = !this.multipleFAError && this.isMasterAccountChecked;
    }
    /*To enable the unselected master accounts while editing and disable the selected master accounts*/
    if (this.masterAccountsValue !== '') {
      this.masterAccountsValue.forEach(account => {
        if (this.manageClientService.editMode && (formValues[account].masterAccountNbr !== true && formValues[account].accountType)) {
          this.createNewClientForm.get('clientMasterAccounts').get([account]).get('masterAccountNbr').disable();
        } else if (this.manageClientService.editMode && formValues[account].accountType === '') {
          this.createNewClientForm.get('clientMasterAccounts').get([account]).get('masterAccountNbr').enable();
          this.createNewClientForm.get('clientMasterAccounts').get([account]).get('accountType').enable();
          this.createNewClientForm.get('clientMasterAccounts').get([account]).get('clientLabel').enable();
        }
      });
    }
  }

  chedkAddlinkMasterAccounts(dropdownSLValues) {
    if (dropdownSLValues === 'ADDLink') {
      this.accountTypeSL = true;
      this.createNewClientForm.patchValue({
        applications: true,
        addlink: true,
        emtr: false,
        businessUnit: (this.manageClientService.editMode ? {
          name: this.businessUnit,
          desc: this.businessUnit + ' Business Unit',
          roleInfoList: null
        } : this.createNewClientForm.get('businessUnit').value)
      });
      this.createNewClientForm.get('addlink').disable();
      this.createNewClientForm.get('emtr').disable();
      /* Enabling LinType if sno SL-10b5-1 is selected */
      if (!this.manageClientService.editMode) {
        this.createNewClientForm.get('businessUnit').enable();
      }
      this.disableLinkTypeFor10b5 = false;
      this.createNewClientForm.get('linkType').markAsPristine();

    } else if (dropdownSLValues === 'EMTR' || dropdownSLValues === '10b5-1') {
      this.accountTypeSL = true;
      if (dropdownSLValues === 'EMTR') {
        this.createNewClientForm.patchValue({
          applications: true,
          addlink: true,
          emtr: true,
          businessUnit: (this.manageClientService.editMode ? {
            name: this.businessUnit,
            desc: this.businessUnit + ' Business Unit',
            roleInfoList: null
          } : this.createNewClientForm.get('businessUnit').value)
        });
        /* Enabling LinType if sno SL-10b5-1 is selected */
        if (!this.manageClientService.editMode) {
          this.createNewClientForm.get('businessUnit').enable();
        }
        this.disableLinkTypeFor10b5 = false;
        this.createNewClientForm.get('linkType').markAsPristine();
      } else {
        /* Disabling LinType if sno SL-10b5-1 is selected */
        this.disableLinkTypeFor10b5 = true;
        this.createNewClientForm.patchValue({
          applications: true,
          addlink: true,
          emtr: true,
          linkType: 'Dual',
          businessUnit: {name: 'SPS', desc: 'SPS Business Unit', roleInfoList: null}
        });
        this.createNewClientForm.get('businessUnit').disable();
      }
      this.createNewClientForm.get('addlink').disable();
      this.createNewClientForm.get('emtr').disable();
    } else {
      this.accountTypeSL = true;
      this.disableLinkTypeFor10b5 = false;
      /*While editing if newly selected master account is unselected, patching the initial value*/
      this.createNewClientForm.patchValue({addlink: this.addlink, emtr: this.emtr});
      if (!this.manageClientService.editMode) {
        this.accountTypeSL = false;
        this.createNewClientForm.get('addlink').enable();
        this.createNewClientForm.get('emtr').enable();
        this.createNewClientForm.get('businessUnit').enable();
        this.createNewClientForm.patchValue({applications: '', addlink: false, emtr: false});
      }
    }
    this.searchButtonClicked = true;
  }

  onChanges(): void {
    this.createNewClientForm.get('authAgentFirstNm').valueChanges.subscribe((val) => {
      if (this.manageClientService.editMode) {
        this.createNewClientForm.get('authAgentFirstNm').markAsPristine();
      }
    });

    this.createNewClientForm.get('authAgentLastNm').valueChanges.subscribe((val) => {
      if (this.manageClientService.editMode) {
        this.createNewClientForm.get('authAgentLastNm').markAsPristine();
      }
    });

    this.createNewClientForm.get('businessUnit').valueChanges.subscribe((value) => {
      this.selectedBU = value ? value.name : '';
      if (this.selectedBU === this.shellConstant.rbsBUText) {
        this.custIDExists = this.multipleFAError = this.emptyDropDownValue = this.duplicateAgentError = false;
        const filterState = 'reset';
        if (!this.manageClientService.editMode) {
          Object.keys(this.createNewClientForm.controls).forEach((controlName) => {
            if (controlName !== 'businessUnit' && controlName !== 'externalAccess') {
              this.createNewClientForm.controls[controlName][filterState]();
            }
          });
          this.searchButtonClicked = false;
          this.createNewClientForm.get('k4Master').enable();
        }
        this.createNewClientForm.get('custId').disable();
        this.createNewClientForm.get('clientName').setValidators(null);
        this.createNewClientForm.get('authAgents').setValidators(null);
        this.createNewClientForm.get('applications').setValidators(null);
        this.createNewClientForm.get('linkType').setValidators(null);
        this.createNewClientForm.get('k4Master').setValidators([Validators.required]);
        this.createNewClientForm.get('registrationName').setValidators([Validators.required]);
        this.previouslySelectedBU = this.shellConstant.rbsBUText;
      } else {
        this.k4MasterExists = false;
        this.errorEmptyK4MasterMsg = '';
        this.registrationNameData = [];
        this.createNewClientForm.get('k4Master').reset();
        if (!this.manageClientService.editMode && this.previouslySelectedBU === this.shellConstant.rbsBUText) {
          this.searchButtonClicked = false;
          this.createNewClientForm.get('custId').enable();
          this.createNewClientForm.get('custId').reset();
        }
        this.createNewClientForm.get('clientName').setValidators([Validators.required, Validators.pattern(this.sharedConstant.clientNamePatternCheck)]);
        this.createNewClientForm.get('linkType').setValidators([Validators.required]);
        this.createNewClientForm.get('applications').setValidators([Validators.required]);
        this.createNewClientForm.get('k4Master').setValidators(null);
        this.createNewClientForm.get('registrationName').setValidators(null);
        if (value.name && value.name !== 'DBS') {
          this.disable407Liable = this.duplicateAgentError = false;
          this.createNewClientForm.get('liable407').reset();
          this.createNewClientForm.get('authAgentFirstNm').reset();
          this.createNewClientForm.get('authAgentLastNm').reset();
          this.createNewClientForm.get('authAgents').setValidators(null);
        } else if (value.name !== 'SPS' && !this.manageClientService.editMode) {
          this.disable407Liable = true;
          this.authAgents = [];
          const authAgentFirstNm = this.shellConstant.manageClients.clientDetailsPage.exception;
          const authAgentLastNm = this.shellConstant.manageClients.clientDetailsPage.dbs;
          this.authAgents.push({authAgentFirstNm, authAgentLastNm});
          this.createNewClientForm.patchValue({
            authAgents: this.authAgents,
            authAgentFirstNm: '',
            authAgentLastNm: ''
          });
          this.createNewClientForm.get('authAgents').setValidators([Validators.required]);
        } else if (value.name !== 'SPS' && this.manageClientService.editMode) {
          this.disable407Liable = true;
          this.createNewClientForm.get('authAgents').setValidators([Validators.required]);
        } else if (value.name !== 'SPS') {
          this.createNewClientForm.get('authAgents').setValidators([Validators.required]);
        }
        this.previouslySelectedBU = 'DBS/RBS';
      }
      this.createNewClientForm.get('clientName').updateValueAndValidity();
      this.createNewClientForm.get('linkType').updateValueAndValidity();
      this.createNewClientForm.get('authAgents').updateValueAndValidity();
      this.createNewClientForm.get('applications').updateValueAndValidity();
      this.createNewClientForm.get('k4Master').updateValueAndValidity();
      this.createNewClientForm.get('registrationName').updateValueAndValidity();
    });

    this.createNewClientForm.get('blanket407').valueChanges.subscribe((value) => {
      if (value !== true) {
        this.createNewClientForm.get('index').reset();
        this.createNewClientForm.get('index').setValidators(null);
      } else {
        this.createNewClientForm.get('index').setValidators([Validators.required]);
      }
      this.createNewClientForm.get('index').updateValueAndValidity();
    });

    this.createNewClientForm.get('liable407').valueChanges.subscribe((value) => {
      if (value !== true) {
        this.createNewClientForm.get('blanket407').reset();
        this.createNewClientForm.get('index').reset();
      }
    });

    this.createNewClientForm.get('custId').valueChanges.subscribe((value) => {
      if (value) {
        this.searchBtnDisabled = false;
      } else {
        this.errorEmptyCustID = false;
        this.searchBtnDisabled = true;
        this.custIDExists = false;
        this.sharedService.clearErrorMessage();
      }
      if (value !== null && this.custIdChangeMsg) {
        this.saveBtnDisabled = true;
      } else {
        this.saveBtnDisabled = false;
      }
    });

    this.createNewClientForm.get('k4Master').valueChanges.subscribe((value) => {
      if (value) {
        this.disableK4MasterSearchBtn = false;
      } else {
        this.disableK4MasterSearchBtn = true;
        this.sharedService.clearErrorMessage();
        this.createNewClientForm.get('registrationName').disable();
      }
      this.k4MasterExists = false;
      this.errorEmptyK4MasterMsg = '';
      this.errorEmptyK4Master = false;
    });

    this.createNewClientForm.get('registrationName').valueChanges.subscribe((value) => {
      this.duplicateClientErrorMsg = false;
      if (value && value.value === this.shellConstant.manageClients.clientDetailsPage.customName) {
        this.createNewClientForm.get('registrationNameOther').setValidators([Validators.required]);
      } else {
        this.createNewClientForm.get('registrationNameOther').reset();
        this.createNewClientForm.get('registrationNameOther').setValidators(null);
      }
      this.createNewClientForm.get('registrationNameOther').updateValueAndValidity();
    });

    this.createNewClientForm.get('clientName').valueChanges.subscribe(val => {
      this.duplicateClientErrorMsg = false;
    });

    this.createNewClientForm.get('registrationNameOther').valueChanges.subscribe(val => {
      this.duplicateClientErrorMsg = false;
    });
  }

  editClient(orgId) {
    this.createNewClientForm.get('custId').disable();
    this.manageClientService.getClientById(orgId).subscribe((res) => {
      this.createNewClientForm.patchValue({
        custId: res.data.custId,
        clientName: res.data.clientName,
        registrationName: {value: res.data.clientName, label: res.data.clientName},
        linkType: res.data.linkType,
        addlink: res.data.addlink === 'Y',
        emtr: res.data.emtr === 'Y',
        businessUnit: {name: res.data.businessUnit, desc: res.data.businessUnit + ' Business Unit', roleInfoList: null},
        commissionCode: res.data.commissionCode,
        liable407: res.data.liable407 === 'Y',
        blanket407: res.data.blanket407 === 'Y',
        index: res.data.index,
        authAgents: res.data.authAgents ? res.data.authAgents : [],
        externalAccess: res.data.externalAccess === 'Y',
        allowCharitableAccounts: res.data.allowCharitableAccounts,
        charitable: res.data.charitable
     });
     this.showDeactivationBtn = res.data.activeStatus === 'Y' ? true : false;
      this.showReactivationBtn = res.data.activeStatus === 'N' ? true : false;
      this.orgId = res.data.orgId;
      this.emtrConversionStatus = res.data.emtrEnablementStatus ? res.data.emtrEnablementStatus : null;
      this.disableLinkTypeFor10b5 = this.createNewClientForm.get('linkType').value === 'Dual';
      this.authAgents = this.createNewClientForm.get('authAgents').value;
      if (res.data.businessUnit === 'DBS') {
        this.masterAccountDropDownData = this.shellConstant.manageClients.clientDetailsPage.masterAccountDropdownWithNo10b5;
        this.charitableClientChange(true);
      } else if (res.data.businessUnit === this.shellConstant.rbsBUText) {
        const masterAcct = res.data.clientMasterAccounts;
        if (masterAcct.length === 1) {
          this.createNewClientForm.get('k4Master').setValue(masterAcct[0].masterAccountNbr);
        } else {
          masterAcct.forEach(obj => {
            if (obj.accountType === this.shellConstant.k4Text) {
              this.createNewClientForm.get('k4Master').setValue(obj.masterAccountNbr);
            }
          });
        }
        this.createNewClientForm.get('k4Master').disable();
      }
      this.createNewClientForm.get('businessUnit').disable();
      this.searchMasterAccounts(res);
      this.createNewClientForm.get('emtr').disable();
      this.updateApplications();
      this.createNewClientForm.get('addlink').disable();
      this.searchBtnDisabled = true;
      this.businessUnit = res.data.businessUnit;
      this.clientId = res.data.clientId;
      this.addlink = res.data.addlink === 'Y';
      this.emtr = res.data.emtr === 'Y';
      this.inactiveClientdata = res.data;
      this.showInfoIcon = this.inactiveClientdata.deactivationInfo ? this.inactiveClientdata.deactivationInfo.reason ? this.inactiveClientdata.deactivationInfo.reason.trim().length > 20 ? true : false : false : false;
    });
  }

  ngOnDestroy() {
    this.sharedService.disableClientSelector.next(this.sharedService.ClientCount === this.sharedConstant.clientCount);

    /* Setting the selectedClientInfo to null, when global client selector is set to All */
    if (!this.manageClientService.editMode && this.sharedService.ClientCount !== this.sharedConstant.clientCount) {
      this.sharedService.selectedClientInfo.next('');
    }

    /* Clearing sessionStorage value for other routes for State management*/
    if (this.router.routerState.snapshot.url.indexOf('manage-clients') === -1) {
      this.sharedService.clearLocalStorage();
    }
  }

  objectKeys(obj) {
    return Object.keys(obj);
  }
}
