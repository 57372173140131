import { Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'ems-step-wizard-buttons',
  templateUrl: './step-wizard-buttons.component.html'
})
export class StepWizardButtonsComponent implements OnInit, AfterViewChecked {
  @Input() disableBtn: any;
  @Input() disableRevertBtn: any;
  @Input() showCancelBtn: any = false;
  @Input() showContinueBtn: any = false;
  @Input() showPreviousBtn: any = false;
  @Input() showRevertBtn: any = false;
  @Input() showCloseBtn: any = false;
  @Input() showSaveBtn: any = false;
  @Input() showSaveCloseBtn: any = false;
  @Input() showActivateBtn: any = false;
  @Input() showSaveActivateBtn: any = false;
  @Input() showSaveContinueBtn: any = false;
  @Input() revertDisable: any = true;
  @Input() showRunReportBtn: any = false;
  @Input() disablePrevBtn: any;
  @Input() showDeActivateBtn: any = false;
  @Input() showReActivateBtn: any = false;
  @Input() disableDeActivate: any;
  @Input() disableReActivate: any;

  @Output() closeBtn = new EventEmitter<boolean>();
  @Output() revert = new EventEmitter<boolean>();
  @Output() save = new EventEmitter<boolean>();
  @Output() saveAndClose = new EventEmitter<boolean>();
  @Output() activate = new EventEmitter<boolean>();
  @Output() goToPrevious = new EventEmitter<boolean>();
  @Output() goToNext = new EventEmitter<boolean>();
  @Output() runReport = new EventEmitter<boolean>();
  @Output() deActivate = new EventEmitter<boolean>();
  @Output() reActivate = new EventEmitter<boolean>();

  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  goToPreviousStep() {
    this.goToPrevious.emit();
  }

  goToNextStep() {
    this.goToNext.emit();
  }

  goToRuleList() {
    this.closeBtn.emit();
  }

  revertChanges() {
    this.revert.emit();
  }

  saveData() {
    this.save.emit();
  }

  activateRule() {
    this.activate.emit();
  }
  saveAndCloseData() {
    this.saveAndClose.emit();
  }

  runSelectedReports() {
    this.runReport.emit();
  }
  deActivateClient() {
    this.deActivate.emit();
  }

  reActivateClient(){
    this.reActivate.emit();
  }
}
