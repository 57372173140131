import {
  AfterViewChecked,
  Inject,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  HostListener
} from '@angular/core';
import {ShellConstant} from '@shell_components/constants/shellConstants';
import {Router} from '@angular/router';
import {AddlinkService} from '@ems/addlink';
import {ShellPagination, ShellPaginationValues} from '@shell_components/model/pagination-model';
import {
  ReportLandingPageTableHeader,
  ReportLandingPageTableHeaders
} from '@shell_components/model/reports-landing-page-table-header';
import {Paginator} from 'primeng/paginator';
import {Subscription} from 'rxjs';
import {AppService} from '@core_services/app.service';
import {ReportsService} from '@core_services/report/reports.service';
import {CustomSortService, DateRangeFilterComponent, SharedConstant, SharedService} from '@ems/shared';
import {LocalStorageService} from '@core_services/local-storage.service';
import {Table} from 'primeng/table';
import * as temp from 'moment';
const moment = temp['default'];
import {HttpResponse} from '@angular/common/http';
import {ReportDetailsModalComponent} from '@shell_components/reports/report-details-modal/report-details-modal.component';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AddlinkConstant} from '../../../../projects/ems/addlink/src/lib/constants/addlinkConstants';
import {ReportListActionMenuItems} from '@shell_components/model/report-list-action-menu';

@Component({
  selector: 'app-reports-list',
  templateUrl: './reports-list.component.html',
})
export class ReportsListComponent implements OnInit, OnDestroy, AfterViewChecked  {

  getConstant = ShellConstant;
  addLinkConstant = AddlinkConstant;
  getSharedConstant = SharedConstant;
  private currentOrgSubscription: Subscription;
  totalReports: any = 0;
  paginationCount: any = 0;
  pageObject: ShellPagination = ShellPaginationValues;
  reportListTableCols: ReportLandingPageTableHeader[] = ReportLandingPageTableHeaders;
  moment = moment;
  @ViewChild('p') paginator: Paginator;
  @ViewChild(Table, { static: true }) tableComponent: Table;
  @ViewChild('reportDetailsModal', { static: true }) reportDetailsModalComponent: ReportDetailsModalComponent;
  @ViewChild('range') rangeCalendar: DateRangeFilterComponent;

  multiSortMeta: any = [];
  getOrgId: any;
  reportsList: any;
  resetTable: any;
  toggle = false;
  filterSelectedDate: any;
  isClearBtnClicked = false;
  reportListForm: UntypedFormGroup;
  filterClicked = false;
  filterBtnDisableForsubmittedBy = false;
  isDisabled = false;
  filterState: any;
  statusSelectedOptions = [];
  storeFilterObj = {};
  selectedAction: any;
  intervalJobs: any = {};
  processID: any = [];
  progressValue: any = 0;
  showSnapshotDetails = false;
  takeActionMenuList = this.getConstant.reports.reportsListPage.reportListDropdownMenuTxt;
  externalAccess = false;
  hasRuleAccess = true;
  reportTypeDropdown: any = [];
  reportTypeDropdownValue = [];
  isDataTrackCalled = false;
  isSortingChanged = false;
  defaultSortDataField = {field: 'reportId', order: -1};
  actionMenu: any[];
  reportId: string;
  showActionMenu: boolean;

  constructor(public addlinkService: AddlinkService, private shellService: AppService, public reportService: ReportsService, public customSortService: CustomSortService,
              public localStorageService: LocalStorageService, private router: Router, public sharedService: SharedService, public fb: UntypedFormBuilder, public el: ElementRef,
              @Inject('entitlementConst') public entitlementConst) {
  }

  @HostListener('document:click', [])
  onClick() { /* Capture the Click Event outside the function */
    if (!this.showActionMenu) {
      this.reportId = '';
    }
    this.showActionMenu = false;
  }

  ngOnInit() {
    this.reportListForm = this.fb.group({
      reportId: [''],
      reportType: [''],
      status: [''],
      submittedDate: [''],
      submittedBy: ['']
    });
    this.pageObject.pageNo = 1;
    this.pageObject.pageSize = 15;
    this.customSortService.sortArr = [];
    this.actionMenu = ReportListActionMenuItems;
    this.multiSortMeta.push(this.defaultSortDataField);
    this.resetTable = true;
    /* If Snapshot report is executed then set the selected action to Snapshot to set the dropdown value */
    if (this.reportService.selectedSnapshotReport) {
      this.showSnapshotDetails = true;
      this.selectedAction = this.getConstant.reports.RulesDetails.snapShot;
    }
    this.currentOrgSubscription = this.sharedService.currentOrg.subscribe(clientInfo => {
      this.sharedService.clearErrorMessage();
      const clientId = this.localStorageService.getProfile();
      this.pageObject.pageSize = 15;
      this.sharedService.tableDropdownVal.next(15);
      this.getOrgId = clientInfo !== null ? clientInfo : clientId ? JSON.parse(clientId).orgId : clientInfo;
      this.sharedService.getOrgId = clientInfo !== null ? clientInfo : clientId ? JSON.parse(clientId).orgId : clientInfo;
      this.shellService.setCurrentOrg(this.getOrgId);
      this.reportService.orgId = this.getOrgId;
      this.reportService.businessUnit = JSON.parse(clientId) && JSON.parse(clientId).businessUnit ? JSON.parse(clientId).businessUnit : '';
      this.hasRuleAccess = this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.ruleSummary.uuid, '', '');
      this.reportService.clientName = JSON.parse(clientId) && JSON.parse(clientId).clientName;
      this.reportService.clientId = JSON.parse(clientId) && JSON.parse(clientId).clientId;
      JSON.parse(clientId) && JSON.parse(clientId).externalAccess === 'Y' ? this.externalAccess = true : this.externalAccess = false;
      this.resetForm();
      this.toggle = false;
      if (!this.resetTable) {
        this.multiSortMeta = [this.defaultSortDataField];
        this.customSortService.resetTable(this.defaultSortDataField, this.tableComponent);
      }
    });
    const newObj = {label: 'All', value: 'All'};
    this.getConstant.reports.reportsListPage.reportListFilter.status.forEach((item) => this.statusSelectedOptions.push(item.value));
    this.reportListForm.patchValue({reportType: newObj, status: this.statusSelectedOptions});

    this.reportListForm.get('submittedBy').valueChanges.subscribe(value => {
      this.filterBtnDisableForsubmittedBy = this.sharedService.isValueLengthGreaterThan2(value);
    });

    this.reportListForm.get('reportId').valueChanges.subscribe(value => {
      if (value) {
        this.isDisabled = !this.isDisabled;
        this.filterState = 'disable';
      } else {
        this.isDisabled = !this.isDisabled;
        this.filterState = 'enable';
      }
      Object.keys(this.reportListForm.controls).forEach((controlName) => {
        if (controlName !== 'reportId') {
          this.reportListForm.controls[controlName][this.filterState]();
          if (this.filterState === 'disable') {
            this.reportListForm.controls[controlName].reset();
            this.rangeCalendar.clearSelection();
          }
        }
      });
      if (this.filterState === 'disable') {
        this.reportListForm.patchValue({
          reportType: newObj,
          status: this.statusSelectedOptions
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
    if (this.processID.length > 0) {
      this.processID.map((data) => {
        window.clearInterval(this.intervalJobs[data]);
      });
    }
    this.reportService.selectedSnapshotReport = false;
    this.sharedService.updatedDate.next('');
  }

  ngAfterViewChecked(): void {
    const elementRef = this.el.nativeElement.querySelector('.p-multiselect-label');
    this.sharedService.setMultiSelectDefaultLabel(elementRef, this.reportListForm, 'status', this.statusSelectedOptions.length);
  }

  customSort(event) {
    if (event.multiSortMeta && this.pageObject.sortField !== this.customSortService.customSort(event)) {
      this.isSortingChanged = (JSON.stringify(event.multiSortMeta) === JSON.stringify([this.defaultSortDataField])) ? false : true;
      const sortArr = this.customSortService.customSort(event);
      this.pageObject.pageNo = 1;
      if (this.totalReports && this.totalReports > this.getSharedConstant.defaultTableRecords) {
        this.paginator.changePage(0);
      }
      this.pageObject = {
        pageNo: this.pageObject.pageNo,
        pageSize: this.pageObject.pageSize,
        sortField: sortArr
      };
      this.getReportsList();
      if (this.paginator) {
        this.paginator.changePage(0);
      }
    }
  }

  paginate(e) {
    if (this.pageObject.pageNo !== e.page + 1) {
      this.sharedService.clearErrorMessage();
      this.pageObject.pageNo = e.page + 1;
      this.getReportsList();
    }
  }

  getReportsList() {
    const localUpdatedFormValues = this.reportListForm.getRawValue();
    const submittedDate = [];
    if (this.filterSelectedDate !== undefined && this.filterSelectedDate !== '' && this.filterSelectedDate !== null) {
      this.filterSelectedDate.forEach(date => {
        submittedDate.push(date);
      });
    }
    localUpdatedFormValues.status.sort();
    const isStatusAll = this.statusSelectedOptions.every((val, index) => val === localUpdatedFormValues.status[index]);
    const statusArr = [];
    if (!isStatusAll) {
      localUpdatedFormValues.status.forEach(status => {
        if (status === 'Processing') {
          status = 'Started';
        }
        statusArr.push(status);
      });
    }
    if (this.filterClicked) {
      if (this.processID.length > 0) {
        this.processID.map((data) => {
          window.clearInterval(this.intervalJobs[data]);
        });
      }
      const formFilterObj: any = {
        orgId: this.getOrgId ? this.getOrgId : '',
        reportId: localUpdatedFormValues.reportId ? localUpdatedFormValues.reportId : null,
        reportType: localUpdatedFormValues.reportType.value,
        statusList: localUpdatedFormValues.status ? (isStatusAll ? localUpdatedFormValues.status = ['All'] : statusArr) : [],
        submittedBy: localUpdatedFormValues.submittedBy ? localUpdatedFormValues.submittedBy.trim() : null,
        submittedFromDate: submittedDate.length > 0 ? moment(submittedDate[0]).format('MM/DD/YYYY') : '',
        submittedToDate: submittedDate.length > 0 ? moment(submittedDate[1]).format('MM/DD/YYYY') : '',
      };
      this.updateformValues(formFilterObj);
      this.storeFilterObj = formFilterObj;
    } else {
      const formFilterObj: any = {
        orgId: this.getOrgId ? this.getOrgId : '',
        reportId: null,
        reportType: localUpdatedFormValues.reportType.value,
        statusList: ['All'],
        submittedBy: null,
        submittedFromDate: '',
        submittedToDate: ''
      };
      this.updateformValues('');
      this.storeFilterObj = formFilterObj;
    }

    if (this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.ruleSummary.uuid, this.entitlementConst.navUUID.accountSummary.uuid, '')) {
      if (!this.hasRuleAccess) {
        this.showSnapshotDetails = false;
        this.selectedAction = this.getConstant.reports.RulesDetails.historyTxt;
      }
      this.reportService.getReportsList(this.showSnapshotDetails, this.pageObject, this.storeFilterObj).subscribe((reports) => {
        this.reportsList = reports.data.content;
        this.totalReports = this.sharedService.totalElementsWithCommas(reports.data.totalElements);
        this.paginationCount = reports.data.totalElements;
        this.resetTable = false;
        this.reportsList.map(data => {
          data.progressValue = 0;
          if (data.status.toLowerCase() === 'started' || data.status.toLowerCase() === 'received' || data.status.toLowerCase() === 'processing') {
            data.status = 'Processing';
            this.processID.push(data.reportId);
            this.intervalJobs[data.reportId] = setInterval(() => {
              this.getReportStatus(data.reportId);
            }, this.getConstant.reqDetailPollingInterval);
          }
        });
      });
    }
  }

  downloadReportFile(data) {
    let port = 'Reports';
    if (this.showSnapshotDetails) {
      port = 'snapshot';
    }
    const fileName = data.reportType + '_' + data.reportId;
    this.sharedService.downloadFileSystem(data.reportId, port).subscribe((response: HttpResponse<Blob>) => {
      this.sharedService.saveFile(response.body, fileName + '.csv');
    });
  }

  public openReportsDetailsPopup(reportId: string, reportType: string): void {
    this.reportDetailsModalComponent.openModal(reportId, reportType, this.showSnapshotDetails);
  }

  /* Method to navigate the user to Preset reports screen on click on report type link */
  navigateToReports(type) {
    /* Set data track value to detect change in page */
    this.sharedService.loadDataTrackValue('client_redirect');
    const link = this.getConstant.reports[type].routerLink;
    this.reportService.setReportName(this.getConstant.reports[type].name);
    this.router.navigate(['ems/reports/preset-reports/' + link]);
  }

  public showSelectedReportTypeReportList(actionValue): void {
    /* Set data track value to detect change report - History/Snapshot - should be triggered only once */
    if (!this.isDataTrackCalled) {
      this.isDataTrackCalled = true;
      this.sharedService.gutTracking('adobe-lc_history|fireonce');
    }
    if (actionValue === this.getConstant.reports.RulesDetails.snapShot) {
      this.showSnapshotDetails = true;
      this.checkSnapshotDetails();
    } else {
      this.showSnapshotDetails = false;
      this.reportTypeDropdown = [];
      this.reportTypeDropdown = [...this.reportTypeDropdownValue];
    }
    this.clickedClearButton();
  }

  getReportStatus(reportIds) {
    this.sharedService.setRefresh.emit(true);
    this.reportService.pollUploadsDetailsByReportId(this.showSnapshotDetails, reportIds).subscribe((res) => {
      for (const upload of res.data) {
        const data = {
          status: upload.status,
          reportId: upload.reportId,
          progressValue: Math.round((res.data[0].processCount / res.data[0].recordCount) * 100)
        };
        this.processReportsResponse(data);
        if (data.reportId === reportIds && (data.status.toLowerCase() !== 'processing')) {
          window.clearInterval(this.intervalJobs[reportIds]);
          this.sharedService.setRefresh.emit(false);
        }
      }
    }, error => {
      window.clearInterval(this.intervalJobs[reportIds]);
    });
  }

  public processReportsResponse(responseObject: any): void {
    for (const report of this.reportsList) {
      if (report.reportId !== null && report.reportId === responseObject.reportId) {
        report.status = responseObject.status;
        report.progressValue = responseObject.progressValue;
        break;
      }
    }
  }

  toggleFunction() {
    this.sharedService.gutTracking('adobe-fl_reportsfilter|fireonce');
    this.toggle = !this.toggle;
    if (this.toggle) {
      this.getReportsTypeValue();
    }
  }

  getSelectedDate(date) {
    this.filterSelectedDate = date;
    this.reportListForm.get('submittedDate').setValue(this.filterSelectedDate);
    if (!this.isClearBtnClicked && this.filterSelectedDate) {
      this.reportListForm.markAsDirty();
    }
  }

  applyFilter() {
    this.sharedService.clearErrorMessage();
    /* Set the selected date value to the manageUserListForm before hitting the Filter API */
    if (this.rangeCalendar) {
      this.rangeCalendar.applyToDate();
      this.getSelectedDate(this.rangeCalendar.dateRangeForm.get('dateSelected').value);
    }
    this.pageObject.pageNo = 1;
    if (this.totalReports && this.totalReports > this.getSharedConstant.defaultTableRecords) {
      this.paginator.changePage(0);
    }
    this.filterClicked = true;
    this.getReportsList();
  }

  clickedClearButton(resetSortFromUI?) {
    this.sharedService.clearErrorMessage();
    this.resetForm(resetSortFromUI);
    this.getReportsList();
  }

  resetForm(resetSortFromUI?) {
    if (resetSortFromUI) {
      this.multiSortMeta = [this.defaultSortDataField];
    }
    this.reportListForm.reset();
    const newObj = {label: 'All', value: 'All'};
    this.reportListForm.patchValue({reportType: newObj, status: this.statusSelectedOptions});
    if (this.toggle) {
      this.isClearBtnClicked = true;
      this.rangeCalendar.clearSelection();
    }
    this.pageObject.pageNo = 1;
    if (this.totalReports && this.totalReports > this.getSharedConstant.defaultTableRecords) {
      this.paginator.changePage(0);
    }
    this.filterClicked = false;
    this.isClearBtnClicked = false;
  }

  updateformValues(formVals) {
    this.sharedService.updatedDate.next(formVals.submittedFromDate ? {name: 'Require Indexing', fromDate: formVals.submittedFromDate, toDate: formVals.submittedToDate } : '');
  }

  /* Method to Populate report type dropdown dynamically */
  getReportsTypeValue() {
    this.reportTypeDropdown = [];
    this.reportTypeDropdownValue = [];
    const pageObject = {
      pageSize: 15,
      pageNo: 0,
      sortField: ''
    };
    this.reportService.getReports(pageObject).subscribe(reports => {
      /* Loop through the response to form the dropdown value */
      reports.data.content.forEach(report => {
        switch (report.name) {
          case this.getConstant.reports.presetReports.reportNames.employeeGrpAssociationTxt:
            report.name = this.getConstant.reportTypeTxt.empGroupAccountTxt;
            break;
          case this.getConstant.reports.presetReports.reportNames.employeeAssociationTxt:
            report.name = this.getConstant.reportTypeTxt.empAccountTxt;
            break;
          case this.getConstant.reportTypeTxt.openOrderDetailsTxt:
            report.name = this.getConstant.reportTypeTxt.openOrderTxt;
            break;
          case this.getConstant.reportTypeTxt.participationPlanAssociationTxt:
            report.name = this.getConstant.reportTypeTxt.participantPlan;
            break;
          case this.getConstant.reports.presetReports.reportNames.participantAssociationTxt:
            report.name = this.getConstant.reportTypeTxt.participantAccountTxt;
            break;
          case this.getConstant.reportTypeTxt.rulesDetailsTxt:
            report.name = this.getConstant.reportTypeTxt.ruleTxt;
            break;
          default:
            break;
        }
        const obj = { label: report.name, value: report.name };
        this.reportTypeDropdownValue.push(obj);
        this.reportTypeDropdownValue.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
      });
      const newObj = { label: 'All', value: 'All' };
      this.reportTypeDropdownValue.unshift(newObj);
      if (this.showSnapshotDetails) {
        this.checkSnapshotDetails();
      } else {
        this.reportTypeDropdown = [...this.reportTypeDropdownValue];
        this.reportListForm.patchValue({ reportType: newObj });
      }
    });
  }

  checkSnapshotDetails() {
      this.reportTypeDropdown = [];
      const snapshotDropdownValue = this.reportTypeDropdownValue.filter(val => val.value === 'All' || val.value === 'Plan' || val.value === 'Rule');
      const newObj = { label: 'Employee', value: 'Employee' };
      if (this.reportService.businessUnit !== this.getConstant.rbsBUText) {
        /* Push 'Employee' option only if the global client dropdown is set to 'All' or DBS/SPS client */
        snapshotDropdownValue.splice(1, 0, newObj);
      }
      this.reportTypeDropdown = [...snapshotDropdownValue];
      const obj = { label: 'All', value: 'All' };
      this.reportListForm.patchValue({ reportType: obj });
  }

  formMenuItem(rowData) {
    const actionItem = ReportListActionMenuItems;
    actionItem.forEach(data => {
      if (data.label === this.getConstant.reports.reportActionMenu.downloadReport && rowData.status.toUpperCase() !== 'COMPLETE') {
        data.disabled = true;
      } else {
        data.disabled = false;
      }
    });
  }

  onActionMenuClick(event: any, rowData: any) {
    this.sharedService.clearErrorMessage();
    const actionItem = event.target.parentElement.innerText;
    if (actionItem === this.getConstant.reports.reportActionMenu.reportDetails) {
      this.openReportsDetailsPopup(rowData.reportId, rowData.reportType);
    } else if (actionItem === this.getConstant.reports.reportActionMenu.downloadReport && rowData.status.toUpperCase() === 'COMPLETE') {
      this.downloadReportFile(rowData);
    }
  }
}
